// src/router/AppRouter.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainLayout } from '../components/layout/MainLayout';
import { ProtectedRoute } from './ProtectedRoute';
import { Home } from '../pages/Home';
import { Login } from '../pages/auth/Login';
import { Register } from '../pages/auth/Register';
import { Dashboard } from '../pages/dashboard/Dashboard';
import { NotFound } from '../pages/NotFound';
import Features from '../pages/Features';
import Pricing from '../pages/Pricing';
import About from '../pages/About';
import Contact from '../pages/Contact';
import OAuthCallback from '../components/auth/OAuthCallback';
import ApiDocumentation from '../pages/docs/api';
import Documentation from '../pages/docs/documentation';
import ReleaseNotes from '../pages/docs/release-notes';
import Tutorials from '../pages/docs/tutorials';
import { BetaRequest } from '../pages/BetaRequest';
import { BetaManagement } from '../pages/admin/BetaManagement';

// Company pages
import Careers from '../pages/company/careers';
import Press from '../pages/company/press';
import Partners from '../pages/company/partners';

// Resource pages
import Blog from '../pages/resources/blog';
import Newsletter from '../pages/resources/newsletter';
import Events from '../pages/resources/events';
import HelpCenter from '../pages/resources/help';

// Legal pages
import Privacy from '../pages/legal/privacy';
import Terms from '../pages/legal/terms';
import Security from '../pages/legal/security';
import Cookies from '../pages/legal/cookies';

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout><Home /></MainLayout>} />
        <Route path="/login" element={<MainLayout><Login /></MainLayout>} />
        <Route path="/register" element={<MainLayout><Register /></MainLayout>} />
        <Route path="/404" element={<MainLayout><NotFound /></MainLayout>} />
        <Route path="/features" element={<MainLayout><Features /></MainLayout>} />
        <Route path="/pricing" element={<MainLayout><Pricing /></MainLayout>} />
        <Route path="/about" element={<MainLayout><About /></MainLayout>} />
        <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
        // In AppRouter.tsx, modify the dashboard routes:
        <Route path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
        <Route path="/auth/github/callback" element={<OAuthCallback />} />
        <Route path="/auth/linkedin/callback" element={<OAuthCallback />} />
        <Route path="/docs" element={<MainLayout><Documentation /></MainLayout>} />
        <Route path="/docs/api" element={<MainLayout><ApiDocumentation /></MainLayout>} />
        <Route path="/docs/releases" element={<MainLayout><ReleaseNotes /></MainLayout>} />
        <Route path="/docs/tutorials" element={<MainLayout><Tutorials /></MainLayout>} />
        <Route path="/beta" element={<MainLayout><BetaRequest /></MainLayout>} />
        {/* Company Routes */}
        <Route path="/careers" element={<MainLayout><Careers /></MainLayout>} />
        <Route path="/press" element={<MainLayout><Press /></MainLayout>} />
        <Route path="/partners" element={<MainLayout><Partners /></MainLayout>} />
        
        {/* Resource Routes */}
        <Route path="/blog" element={<MainLayout><Blog /></MainLayout>} />
        <Route path="/newsletter" element={<MainLayout><Newsletter /></MainLayout>} />
        <Route path="/events" element={<MainLayout><Events /></MainLayout>} />
        <Route path="/help" element={<MainLayout><HelpCenter /></MainLayout>} />

        {/* Legal Routes */}
        <Route path="/privacy" element={<MainLayout><Privacy /></MainLayout>} />
        <Route path="/terms" element={<MainLayout><Terms /></MainLayout>} />
        <Route path="/security" element={<MainLayout><Security /></MainLayout>} />
        <Route path="/cookies" element={<MainLayout><Cookies /></MainLayout>} />
        <Route 
  path="/admin/beta" 
  element={
    
      <BetaManagement />
    
  } 
/>
      </Routes>
    </BrowserRouter>
  );
};