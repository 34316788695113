import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  WidgetType, 
  ChartWidgetConfig,
  DEFAULT_CHART_CONFIG,
  DEFAULT_VISUAL_CONFIG,
  DEFAULT_DATA_CONFIG
} from '../../types/widget';
import { creditService } from '../../services/creditService';
import { 
  BarChart, 
  LineChart, 
  AreaChart,
  Plus,
  Trash2,
  Sparkles,
  AlertCircle
} from 'lucide-react';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { Button } from '../ui/button';

const ChartWidgetCreator: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { createWidget, isLoading, error: serviceError } = useWidget();
  const [error, setError] = useState<string | null>(null);
  const [creditCost, setCreditCost] = useState(0);

  const [widgetConfig, setWidgetConfig] = useState<ChartWidgetConfig>({
    name: '',
    description: '',
    widgetType: WidgetType.CHART,
    function: '',
    presentation: '',
    chartConfig: DEFAULT_CHART_CONFIG,
    visual: DEFAULT_VISUAL_CONFIG,
    data: DEFAULT_DATA_CONFIG,
    inputs: {}
  });

  useEffect(() => {
    const cost = creditService.calculateWidgetCost(
      widgetConfig.function,
      WidgetType.CHART
    );
    setCreditCost(cost);
  }, [widgetConfig.function]);

  // src/components/widgets/ChartWidgetCreator.tsx
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    try {
      const chartConfig: ChartWidgetConfig = {
        name: widgetConfig.name,
        widgetType: WidgetType.CHART,
        function: widgetConfig.function,
        presentation: widgetConfig.presentation,
        chartConfig: {
          type: 'line',
          metrics: ['price', 'rsi'],
          xAxis: {
            dataKey: 'time',
            label: 'Time',
            tickRotation: 0
          },
          yAxis: {
            dataKey: 'price',
            label: 'Price',
            tickRotation: 0
          },
          showLegend: true,
          showGrid: true,
          showTooltip: true,
          stacked: false,
          colors: ['#3B82F6', '#10B981']
        }
      };
  
      await createWidget({
        name: chartConfig.name,
        widgetType: chartConfig.widgetType,
        config: chartConfig
      });
  
      navigate('/dashboard/library');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create widget');
    }
  };

  const handleConfigChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setWidgetConfig(prev => {
      if (name === 'name' || name === 'description' || name === 'function' || name === 'presentation') {
        return {
          ...prev,
          [name]: value,
        };
      }

      if (name === 'type') {
        return {
          ...prev,
          chartConfig: {
            ...prev.chartConfig,
            type: value as 'line' | 'bar' | 'area'
          }
        };
      }

      return prev;
    });
  };

  const handleMetricChange = (index: number, value: string) => {
    setWidgetConfig(prev => ({
      ...prev,
      chartConfig: {
        ...prev.chartConfig,
        metrics: prev.chartConfig.metrics.map((m, i) => i === index ? value : m)
      }
    }));
  };

  const handleAddMetric = () => {
    setWidgetConfig(prev => ({
      ...prev,
      chartConfig: {
        ...prev.chartConfig,
        metrics: [...prev.chartConfig.metrics, '']
      }
    }));
  };

  const handleRemoveMetric = (index: number) => {
    setWidgetConfig(prev => ({
      ...prev,
      chartConfig: {
        ...prev.chartConfig,
        metrics: prev.chartConfig.metrics.filter((_, i) => i !== index)
      }
    }));
  };

  

  const inputClasses = `w-full rounded-lg px-4 py-2 ${
    isDarkMode 
      ? 'bg-gray-800 border-gray-700 text-white' 
      : 'bg-white border-gray-200 text-gray-900'
  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`;

  const labelClasses = `block text-sm font-medium ${
    isDarkMode ? 'text-gray-200' : 'text-gray-700'
  } mb-2`;

  return (
    <div className="max-w-3xl mx-auto">
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg p-6`}>
        <div className="flex items-center justify-between mb-6">
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Create Chart Widget
          </h2>
          <div className="flex items-center space-x-2 text-blue-500">
            <Sparkles className="w-5 h-5" />
            <span>Cost: {creditCost} credits</span>
          </div>
        </div>

        {(error || serviceError) && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="w-4 h-4 mr-2" />
            {error || serviceError}
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Basic Info Section */}
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className={labelClasses}>
                Widget Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={widgetConfig.name}
                onChange={handleConfigChange}
                className={inputClasses}
                placeholder="Enter widget name..."
              />
            </div>

            <div>
              <label htmlFor="description" className={labelClasses}>
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={widgetConfig.description}
                onChange={handleConfigChange}
                className={inputClasses}
                rows={2}
                placeholder="Describe your widget..."
              />
            </div>
          </div>

          {/* Chart Configuration Section */}
          <div className="space-y-4">
            <h3 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Chart Configuration
            </h3>

            <div>
              <label htmlFor="type" className={labelClasses}>
                Chart Type
              </label>
              <div className="grid grid-cols-3 gap-4">
                {[
                  { type: 'line', icon: LineChart, label: 'Line Chart' },
                  { type: 'bar', icon: BarChart, label: 'Bar Chart' },
                  { type: 'area', icon: AreaChart, label: 'Area Chart' }
                ].map(({ type, icon: Icon, label }) => (
                  <button
                    key={type}
                    type="button"
                    onClick={() => handleConfigChange({
                      target: { name: 'type', value: type }
                    } as any)}
                    className={`flex items-center justify-center p-4 rounded-lg border-2 transition-colors ${
                      widgetConfig.chartConfig.type === type
                        ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                        : 'border-gray-200 dark:border-gray-700'
                    }`}
                  >
                    <Icon className={widgetConfig.chartConfig.type === type ? 'text-blue-500' : ''} />
                    <span className="ml-2">{label}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* Metrics Configuration */}
            <div>
              <label className={labelClasses}>
                Metrics
                <span className="text-sm text-gray-500 ml-2">(at least one required)</span>
              </label>
              <div className="space-y-2">
                {widgetConfig.chartConfig.metrics.map((metric, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={metric}
                      onChange={(e) => handleMetricChange(index, e.target.value)}
                      className={inputClasses}
                      placeholder="e.g., price, volume"
                    />
                    <Button
                      type="button"
                      variant="destructive"
                      size="icon"
                      onClick={() => handleRemoveMetric(index)}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleAddMetric}
                  className="w-full"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Metric
                </Button>
              </div>
            </div>
          </div>

          {/* Function and Presentation */}
          <div className="space-y-4">
            <div>
              <label htmlFor="function" className={labelClasses}>
                Widget Function
              </label>
              <textarea
                id="function"
                name="function"
                value={widgetConfig.function}
                onChange={handleConfigChange}
                className={inputClasses}
                rows={3}
                placeholder="Describe what your widget should do..."
              />
            </div>

            <div>
              <label htmlFor="presentation" className={labelClasses}>
                Presentation Style
              </label>
              <textarea
                id="presentation"
                name="presentation"
                value={widgetConfig.presentation}
                onChange={handleConfigChange}
                className={inputClasses}
                rows={3}
                placeholder="Describe how your widget should look..."
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button
              type="submit"
              disabled={isLoading}
              className="flex items-center"
            >
              {isLoading ? (
                <>
                  <LoadingSpinner size="sm" className="mr-2" />
                  Creating...
                </>
              ) : (
                <>
                  <Sparkles className="w-4 h-4 mr-2" />
                  Create Widget
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChartWidgetCreator;