// src/components/widgets/customization/DataSettings.tsx
import React, { useState } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { Section } from './shared/Section';
import { 
  Database, 
  Filter, 
  RefreshCw, 
  Plus, 
  Trash2 
} from 'lucide-react';
import { DataSettings as DataSettingsType } from '../../../types/shared';

interface DataSettingsProps {
  settings: DataSettingsType;
  onChange: (settings: Partial<DataSettingsType>) => void;
}

interface FilterConfig {
  field: string;
  operator: 'equals' | 'contains' | 'greater' | 'less' | 'between' | 'in';
  value: string;
}

const filterOperators = [
  { value: 'equals', label: 'Equals' },
  { value: 'contains', label: 'Contains' },
  { value: 'greater', label: 'Greater Than' },
  { value: 'less', label: 'Less Than' },
  { value: 'between', label: 'Between' },
  { value: 'in', label: 'In List' }
] as const;

const aggregationMethods = [
  { value: 'none', label: 'None' },
  { value: 'sum', label: 'Sum' },
  { value: 'average', label: 'Average' },
  { value: 'count', label: 'Count' },
  { value: 'min', label: 'Minimum' },
  { value: 'max', label: 'Maximum' }
] as const;

export const DataSettingsPanel: React.FC<DataSettingsProps> = ({
  settings,
  onChange
}) => {
  const { isDarkMode } = useTheme();
  const [expandedSections, setExpandedSections] = useState({
    aggregation: true,
    filters: true,
    refresh: true
  });

  const handleAddFilter = () => {
    const newFilter: FilterConfig = {
      field: '',
      operator: 'equals',
      value: ''
    };
    onChange({
      ...settings,
      filtering: [...(settings.filtering || []), newFilter]
    });
  };

  const handleUpdateFilter = (index: number, updates: Partial<FilterConfig>) => {
    const newFilters = [...(settings.filtering || [])];
    newFilters[index] = { ...newFilters[index], ...updates };
    onChange({
      ...settings,
      filtering: newFilters
    });
  };

  const handleRemoveFilter = (index: number) => {
    const newFilters = (settings.filtering || []).filter((_: FilterConfig, i: number) => i !== index);
    onChange({
      ...settings,
      filtering: newFilters
    });
  };

  return (
    <div className="space-y-6">
      <Section
        title="Data Aggregation"
        icon={Database}
        expanded={expandedSections.aggregation}
        onToggle={() => setExpandedSections(prev => ({
          ...prev,
          aggregation: !prev.aggregation
        }))}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">
              Aggregation Method
            </label>
            <select
              value={settings.aggregation || 'none'}
              onChange={(e) => onChange({
                ...settings,
                aggregation: e.target.value
              })}
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white' 
                  : 'bg-white border-gray-300 text-gray-900'
              }`}
            >
              {aggregationMethods.map(method => (
                <option key={method.value} value={method.value}>
                  {method.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Section>

      <Section
        title="Filters"
        icon={Filter}
        expanded={expandedSections.filters}
        onToggle={() => setExpandedSections(prev => ({
          ...prev,
          filters: !prev.filters
        }))}
      >
        <div className="space-y-4">
          {(settings.filtering || []).map((filter: FilterConfig, index: number) => (
            <div 
              key={index} 
              className={`p-4 rounded-lg border ${
                isDarkMode ? 'border-gray-700' : 'border-gray-200'
              }`}
            >
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium">Filter {index + 1}</span>
                <button
                  onClick={() => handleRemoveFilter(index)}
                  className="p-1 text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>

              <div className="space-y-2">
                <input
                  type="text"
                  value={filter.field}
                  onChange={(e) => handleUpdateFilter(index, { field: e.target.value })}
                  placeholder="Field name"
                  className={`w-full rounded-lg px-3 py-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300 text-gray-900'
                  }`}
                />

                <select
                  value={filter.operator}
                  onChange={(e) => handleUpdateFilter(index, { 
                    operator: e.target.value as FilterConfig['operator']
                  })}
                  className={`w-full rounded-lg px-3 py-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300 text-gray-900'
                  }`}
                >
                  {filterOperators.map(op => (
                    <option key={op.value} value={op.value}>
                      {op.label}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  value={filter.value}
                  onChange={(e) => handleUpdateFilter(index, { value: e.target.value })}
                  placeholder="Value"
                  className={`w-full rounded-lg px-3 py-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300 text-gray-900'
                  }`}
                />
              </div>
            </div>
          ))}

          <button
            onClick={handleAddFilter}
            className={`w-full py-2 rounded-lg border-2 border-dashed ${
              isDarkMode
                ? 'border-gray-700 hover:border-gray-600'
                : 'border-gray-300 hover:border-gray-400'
            } flex items-center justify-center`}
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Filter
          </button>
        </div>
      </Section>

      <Section
        title="Auto Refresh"
        icon={RefreshCw}
        expanded={expandedSections.refresh}
        onToggle={() => setExpandedSections(prev => ({
          ...prev,
          refresh: !prev.refresh
        }))}
      >
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium">Enable Auto Refresh</label>
            <input
              type="checkbox"
              checked={settings.autoRefresh}
              onChange={(e) => onChange({
                ...settings,
                autoRefresh: e.target.checked
              })}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
          </div>

          {settings.autoRefresh && (
            <div>
              <label className="block text-sm font-medium mb-2">
                Refresh Interval (seconds)
              </label>
              <input
                type="range"
                min="5"
                max="300"
                step="5"
                value={settings.refreshInterval || 30}
                onChange={(e) => onChange({
                  ...settings,
                  refreshInterval: Number(e.target.value)
                })}
                className="w-full"
              />
              <div className="flex justify-between text-xs mt-1">
                <span>5s</span>
                <span>5m</span>
              </div>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default DataSettingsPanel;