import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

export interface PreviewProps {
    className?: string;
  }
  
export const PriceChartPreview: React.FC<PreviewProps> = ({ className = '' }) => {
  const { isDarkMode } = useTheme();
  
  const colors = {
    bg: isDarkMode ? '#1f2937' : '#ffffff',
    line: isDarkMode ? '#60a5fa' : '#2563eb',
    grid: isDarkMode ? '#374151' : '#e5e7eb',
    text: isDarkMode ? '#9ca3af' : '#6b7280',
    upBar: isDarkMode ? 'rgba(34, 197, 94, 0.2)' : 'rgba(34, 197, 94, 0.1)',
    downBar: isDarkMode ? 'rgba(239, 68, 68, 0.2)' : 'rgba(239, 68, 68, 0.1)',
  };

  return (
    <div className={`w-full h-full overflow-hidden rounded-lg border ${
      isDarkMode ? 'border-gray-700' : 'border-gray-200'
    } ${className}`}>
      <svg
        viewBox="0 0 400 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-full"
      >
        {/* Background */}
        <rect width="400" height="240" fill={colors.bg} />
        
        {/* Grid Lines */}
        {Array.from({ length: 6 }).map((_, i) => (
          <line
            key={`grid-${i}`}
            x1="0"
            y1={40 + i * 40}
            x2="400"
            y2={40 + i * 40}
            stroke={colors.grid}
            strokeWidth="1"
            strokeDasharray="4 4"
          />
        ))}
        
        {/* Price Line */}
        <path
          d="M 0 180 C 40 160, 80 190, 120 140 C 160 90, 200 180, 240 120 C 280 60, 320 100, 360 80 C 380 70, 400 60, 400 60"
          stroke={colors.line}
          strokeWidth="2"
          fill="none"
        />
        
        {/* Volume Bars */}
        {Array.from({ length: 20 }).map((_, i) => {
          const x = i * 20;
          const height = Math.random() * 30 + 10;
          const y = 240 - height;
          return (
            <rect
              key={`vol-${i}`}
              x={x}
              y={y}
              width="16"
              height={height}
              fill={Math.random() > 0.5 ? colors.upBar : colors.downBar}
            />
          );
        })}
        
        {/* Y-Axis Labels */}
        <text x="5" y="20" fill={colors.text} fontSize="12">200</text>
        <text x="5" y="240" fill={colors.text} fontSize="12">0</text>
      </svg>
    </div>
  );
};

export const VolumeProfilePreview: React.FC<PreviewProps> = ({ className = '' }) => {
  const { isDarkMode } = useTheme();
  
  const colors = {
    bg: isDarkMode ? '#1f2937' : '#ffffff',
    price: isDarkMode ? '#60a5fa' : '#2563eb',
    volume: isDarkMode ? '#4b5563' : '#e5e7eb',
    text: isDarkMode ? '#9ca3af' : '#6b7280',
    poc: isDarkMode ? '#f59e0b' : '#d97706',
  };

  return (
    <div className={`w-full h-full overflow-hidden rounded-lg border ${
      isDarkMode ? 'border-gray-700' : 'border-gray-200'
    } ${className}`}>
      <svg
        viewBox="0 0 400 240"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-full"
      >
        {/* Background */}
        <rect width="400" height="240" fill={colors.bg} />
        
        {/* Volume Profile Bars */}
        {Array.from({ length: 12 }).map((_, i) => {
          const y = 20 + i * 16;
          const width = Math.random() * 200 + 50;
          return (
            <rect
              key={`vp-${i}`}
              x="200"
              y={y}
              width={width}
              height="12"
              fill={colors.volume}
              opacity="0.5"
            />
          );
        })}
        
        {/* Point of Control */}
        <rect
          x="200"
          y="120"
          width="250"
          height="4"
          fill={colors.poc}
        />
        
        {/* Price Line */}
        <path
          d="M 200 20 C 240 60, 220 120, 260 160 C 300 200, 280 220, 320 220"
          stroke={colors.price}
          strokeWidth="2"
          fill="none"
        />
        
        {/* Value Area Lines */}
        <line x1="200" y1="80" x2="380" y2="80" stroke={colors.text} strokeWidth="1" strokeDasharray="4 4" />
        <line x1="200" y1="160" x2="380" y2="160" stroke={colors.text} strokeWidth="1" strokeDasharray="4 4" />
        
        {/* Labels */}
        <text x="205" y="76" fill={colors.text} fontSize="10">VA High</text>
        <text x="205" y="174" fill={colors.text} fontSize="10">VA Low</text>
        <text x="205" y="116" fill={colors.poc} fontSize="10">POC</text>
      </svg>
    </div>
  );
};

export interface TemplateCardProps {
    title: string;
    description: string;
    PreviewComponent: React.FC<PreviewProps>;
    onClick: () => void;
  }
  
// Template Card Component

export const TemplateCard: React.FC<TemplateCardProps> = ({ 
    title, 
    description, 
    PreviewComponent, 
    onClick 
  }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <button
      onClick={onClick}
      className={`group relative rounded-2xl ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg hover:shadow-xl transition-all duration-300 p-6 text-left w-full`}
    >
      {/* Preview */}
      <div className="aspect-w-16 aspect-h-9 mb-4">
        <PreviewComponent />
      </div>

      {/* Content */}
      <div>
        <h3 className={`text-lg font-semibold mb-2 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          {title}
        </h3>
        <p className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {description}
        </p>
      </div>
    </button>
  );
};