// src/services/widgetService.ts
// src/services/widgetService.ts
import api from './api';
import { 
  Widget,
  WidgetType,
  WidgetConfig,
  WidgetCreateInput,
  ChartWidgetConfig,
  WidgetUpdateInput
} from '../types/widget';
import { creditService } from './creditService';
import { claudeService } from './claudeService';

export class WidgetService {
  private static instance: WidgetService;
  private widgets: Map<string, Widget> = new Map();
  private listeners: Set<() => void> = new Set();

  private constructor() {}

  static getInstance(): WidgetService {
    if (!WidgetService.instance) {
      WidgetService.instance = new WidgetService();
    }
    return WidgetService.instance;
  }

  async createWidget(params: WidgetCreateInput): Promise<Widget> {
    try {
      // Calculate credits cost
      const cost = creditService.calculateWidgetCost(
        params.config.function,
        params.widgetType
      );

      // Format the config for code generation
      const formattedConfig = {
        ...params.config,
        chartConfig: params.widgetType === WidgetType.CHART ? {
          type: (params.config as ChartWidgetConfig).chartConfig?.type || 'line',
          metrics: (params.config as ChartWidgetConfig).chartConfig?.metrics || [],
          xAxis: {
            dataKey: (params.config as ChartWidgetConfig).chartConfig?.xAxis?.dataKey || '',
            label: (params.config as ChartWidgetConfig).chartConfig?.xAxis?.label || ''
          },
          yAxis: {
            dataKey: (params.config as ChartWidgetConfig).chartConfig?.yAxis?.dataKey || 'value',
            label: (params.config as ChartWidgetConfig).chartConfig?.yAxis?.label || 'Value'
          },
          showLegend: true,
          showGrid: true,
          showTooltip: true,
          stacked: false
        } : undefined
      };

      // Generate widget code
      const generatedCode = await claudeService.generateWidgetCode({
        widgetType: params.widgetType,
        config: formattedConfig
      });

      // Create widget request data
      const widgetData = {
        name: params.name,
        widgetType: params.widgetType,
        config: formattedConfig,
        content: {
          code: generatedCode,
          data: [],
          component: generatedCode // Add the component code here
        },
        creditCost: cost,
        isPublic: false
      };

      // Create the widget
      const { data } = await api.post<Widget>('/widgets', widgetData);

      // Update local cache
      this.widgets.set(data.id, data);
      this.notifyListeners();

      return data;
    } catch (error) {
      console.error('Widget creation error:', error);
      throw error;
    }
  }

  // Keep existing methods
  async generateContent(id: string, inputs: Record<string, any>): Promise<Widget> {
    try {
      const widget = this.widgets.get(id);
      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = await api.post<Widget>(`/widgets/${id}/generate`, { inputs });
      this.widgets.set(data.id, data);
      this.notifyListeners();
      return data;
    } catch (error) {
      console.error('Content generation error:', error);
      throw this.handleError(error);
    }
  }


  async updateWidget(id: string, updates: WidgetUpdateInput): Promise<Widget> {
    try {
      const { data } = await api.put<Widget>(`/widgets/${id}`, updates);
      
      this.widgets.set(data.id, data);
      this.notifyListeners();
      
      return data;
    } catch (error) {
      console.error('Widget update error:', error);
      throw this.handleError(error);
    }
  }

  async deleteWidget(id: string): Promise<void> {
    try {
      await api.delete(`/widgets/${id}`);
      this.widgets.delete(id);
      this.notifyListeners();
    } catch (error) {
      console.error('Widget deletion error:', error);
      throw this.handleError(error);
    }
  }

  async getUserWidgets(): Promise<Widget[]> {
    try {
      const { data } = await api.get<Widget[]>('/widgets');
      data.forEach(widget => this.widgets.set(widget.id, widget));
      this.notifyListeners();
      return data;
    } catch (error) {
      console.error('Error fetching widgets:', error);
      throw new Error('Failed to fetch widgets');
    }
  }

  async getWidget(id: string): Promise<Widget> {
    try {
      const { data } = await api.get<Widget>(`/widgets/${id}`);
      this.widgets.set(data.id, data);
      this.notifyListeners();
      return data;
    } catch (error) {
      console.error('Error fetching widget:', error);
      throw this.handleError(error);
    }
  }

  

  

  async duplicateWidget(id: string, newName?: string): Promise<Widget> {
    try {
      const widget = await this.getWidget(id);
      if (!widget) {
        throw new Error('Widget not found');
      }

      // Create new widget with same config but new name
      const newWidget = await this.createWidget({
        ...widget,
        name: newName || `Copy of ${widget.name}`,
        config: widget.config
      });

      return newWidget;
    } catch (error) {
      console.error('Widget duplication error:', error);
      throw this.handleError(error);
    }
  }

  subscribe(listener: () => void): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  private notifyListeners() {
    this.listeners.forEach(listener => listener());
  }

  private handleError(error: unknown): Error {
    console.error('Widget service error:', error);
    
    // Handle specific error types
    if (error instanceof Error) {
      // API errors
      if ('response' in error) {
        const apiError = error as any;
        if (apiError.response?.data?.message) {
          return new Error(apiError.response.data.message);
        }
      }
      return error;
    }

    // Unknown errors
    return new Error('An unknown error occurred in widget service');
  }

  
  }

  


export const widgetService = WidgetService.getInstance();