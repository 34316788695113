import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AuthResponse, ApiError } from '../types/auth';
interface ErrorResponse {
  message: string;
  details?: string;
  code?: string;
}
interface ApiResponse<T> {
  data: T;
}


class ApiService {
  private static instance: ApiService;
  private client: AxiosInstance;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    this.setupInterceptors();
  }

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  getClient(): AxiosInstance {
    return this.client;
  }

  

  private handleRequest(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
  
    if (process.env.NODE_ENV === 'development') {
      console.log('API Request:', {
        method: config.method,
        url: config.url,
        data: config.data
      });
    }
  
    return config;
  }

  private setupInterceptors(): void {
    this.client.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers = config.headers || {};
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error)
    );
  }
  

  private handleRequestError(error: AxiosError): Promise<never> {
    return Promise.reject(this.normalizeError(error));
  }

  private handleResponse(response: AxiosResponse): AxiosResponse {
    // Log response in development
    if (process.env.NODE_ENV === 'development') {
      console.log('API Response:', {
        status: response.status,
        data: response.data
      });
    }

    return response;
  }

  private handleResponseError(error: AxiosError): Promise<never> {
    const normalizedError = this.normalizeError(error);

    // Handle authentication errors
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    // Handle rate limiting
    if (error.response?.status === 429) {
      // Implement retry logic or user notification
    }

    return Promise.reject(normalizedError);
  }

  private normalizeError(error: AxiosError): Error {
    if (error.response?.data) {
      const errorData = error.response.data as ErrorResponse;
      const message = errorData.message || 'An unknown error occurred';
      const details = errorData.details ? `: ${errorData.details}` : '';
      return new Error(`${message}${details}`);
    }

    if (error.message === 'Network Error') {
      return new Error('Unable to connect to the server. Please check your internet connection.');
    }

    return error;
  }


  // Utility methods for common operations
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<{ data: T }> {
    return this.client.get<T>(url, config);
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<{ data: T }> {
    return this.client.post<T>(url, data, config);
  }

  async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<{ data: T }> {
    return this.client.put<T>(url, data, config);
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<{ data: T }> {
    return this.client.delete<T>(url, config);
  }
}

const apiService = ApiService.getInstance();
export const api = apiService.getClient();
export default apiService;