// client/src/pages/dashboard/WidgetSettings.tsx
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ErrorAlert } from '../../components/common/ErrorAlert';

export const WidgetSettings: React.FC = () => {
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    defaultRefreshInterval: '30',
    theme: 'light',
    notifications: true,
  });
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [savedMessage, setSavedMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    setSavedMessage(null);

    try {
      // Implement settings save functionality
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
      setSavedMessage('Settings saved successfully');
    } catch (err) {
      setError('Failed to save settings');
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;
    setSettings(prev => ({
      ...prev,
      [e.target.name]: value
    }));
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Widget Settings
          </h3>
          
          {error && (
            <div className="mt-4">
              <ErrorAlert message={error} onClose={() => setError(null)} />
            </div>
          )}

          {savedMessage && (
            <div className="mt-4 p-4 bg-green-50 border-l-4 border-green-400 text-green-700">
              {savedMessage}
            </div>
          )}

          <form onSubmit={handleSubmit} className="mt-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Default Refresh Interval
              </label>
              <select
                name="defaultRefreshInterval"
                value={settings.defaultRefreshInterval}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="30">30 seconds</option>
                <option value="60">1 minute</option>
                <option value="300">5 minutes</option>
                <option value="600">10 minutes</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Theme
              </label>
              <select
                name="theme"
                value={settings.theme}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
                <option value="system">System</option>
              </select>
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="notifications"
                  name="notifications"
                  type="checkbox"
                  checked={settings.notifications}
                  onChange={handleChange}
                  className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="notifications" className="font-medium text-gray-700">
                  Enable notifications
                </label>
                <p className="text-gray-500">
                  Receive notifications when widgets update or encounter errors.
                </p>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={isSaving}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save settings'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};