// src/components/widgets/WidgetCustomizationPanel.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Widget, isChartWidget } from '../../types/widget';
import { 
  VisualSettings,
  DataSettings,
  ChartSettings, 
  AIMessage
} from '../../types/shared';
import { 
  CustomizationState,
  CustomizationType
} from '../../types/customization';
import { useWidgetCustomization } from '../../hooks/useWidgetCustomization';
import { VisualSettingsPanel } from './customization/VisualSettings';
import { DataSettingsPanel } from './customization/DataSettings';
import { ChartSettings as ChartSettingsPanel } from './customization/ChartSettings';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { 
  SlidersHorizontal,
  Database,
  LineChart,
  Save,
  RotateCw,
  ArrowLeft
} from 'lucide-react';

// src/components/widgets/WidgetCustomizationPanel.tsx
interface WidgetCustomizationPanelProps {
  widget: Widget;
  onSave: (config: any) => Promise<void>;
  onAIRefine?: (instruction: string, context: AIMessage[]) => Promise<string>;
  className?: string;
}

interface TabConfig {
  id: CustomizationType;
  icon: React.FC<{ className?: string }>;
  label: string;
  showForTypes?: string[];
}

const tabs: TabConfig[] = [
  { id: 'visual', icon: SlidersHorizontal, label: 'Visual' },
  { id: 'data', icon: Database, label: 'Data' },
  { id: 'chart', icon: LineChart, label: 'Chart', showForTypes: ['CHART'] }
];

export const WidgetCustomizationPanel: React.FC<WidgetCustomizationPanelProps> = ({
  widget,
  onSave,
  className
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<CustomizationType>('visual');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    customizations,
    hasUnsavedChanges,
    applyCustomization,
    resetCustomizations
  } = useWidgetCustomization(widget);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const updatedConfig = {
        ...widget.config,
        visual: customizations.visual.theme.config,
        data: customizations.data.settings,
        ...(isChartWidget(widget.config) && customizations.chart && {
          chartConfig: customizations.chart.settings
        })
      };
      await onSave(updatedConfig);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save changes');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        navigate('/dashboard/library');
      }
    } else {
      navigate('/dashboard/library');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'visual':
        return (
          <VisualSettingsPanel
            settings={customizations.visual.theme.config}
            onChange={(settings: Partial<VisualSettings>) => 
              applyCustomization({ category: 'visual', settings })}
          />
        );
      case 'data':
        return (
          <DataSettingsPanel
            settings={customizations.data.settings}
            onChange={(settings: Partial<DataSettings>) => 
              applyCustomization({ category: 'data', settings })}
          />
        );
      case 'chart':
        return isChartWidget(widget.config) && customizations.chart ? (
          <ChartSettingsPanel
            settings={customizations.chart.settings}
            onChange={(settings: Partial<ChartSettings>) => 
              applyCustomization({ category: 'chart', settings })}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <button onClick={handleBack} className="flex items-center text-gray-600">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Library
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={resetCustomizations}
            disabled={!hasUnsavedChanges || isLoading}
            className="text-gray-600 hover:text-gray-900"
          >
            <RotateCw className="w-5 h-5" />
          </button>
          <button
            onClick={handleSave}
            disabled={!hasUnsavedChanges || isLoading}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg
            hover:bg-blue-700 disabled:opacity-50"
          >
            {isLoading ? (
              <LoadingSpinner size="sm" className="mr-2" />
            ) : (
              <Save className="w-5 h-5 mr-2" />
            )}
            Save Changes
          </button>
        </div>
      </div>

      {error && (
        <Alert variant="destructive" className="m-4">
          {error}
        </Alert>
      )}

      {/* Tabs */}
      <div className="flex space-x-4 p-4 border-b">
        {tabs.map(tab => {
          if (tab.showForTypes && !tab.showForTypes.includes(widget.widgetType)) {
            return null;
          }
          return (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center px-4 py-2 rounded-lg ${
                activeTab === tab.id
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <tab.icon className="w-5 h-5 mr-2" />
              {tab.label}
            </button>
          );
        })}
      </div>

      {/* Tab Content */}
      <div className="flex-1 p-4 overflow-auto">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default WidgetCustomizationPanel;