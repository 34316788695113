import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

import {
  Hand,
  Building2,
  Layers,
  ArrowRight,
  CheckCircle2,
  ExternalLink,
  Code,
  Database,
  Globe,
  BadgeCheck,
  Users,
  BarChart
} from 'lucide-react';

interface PartnerType {
  id: string;
  title: string;
  description: string;
  benefits: string[];
  icon: React.FC<{ className?: string }>;
}

interface FeaturedPartner {
  id: string;
  name: string;
  logo: string;
  description: string;
  type: string;
  link: string;
}

const partnerTypes: PartnerType[] = [
  {
    id: 'technology',
    title: 'Technology Partners',
    description: 'Integrate your technology with our platform to provide enhanced trading capabilities.',
    icon: Code,
    benefits: [
      'Access to API integration tools',
      'Technical documentation and support',
      'Co-marketing opportunities',
      'Partner portal access'
    ]
  },
  {
    id: 'data',
    title: 'Data Providers',
    description: 'Deliver your market data through our platform to reach more traders.',
    icon: Database,
    benefits: [
      'Data distribution platform',
      'Custom integration options',
      'Revenue sharing model',
      'Usage analytics'
    ]
  },
  {
    id: 'reseller',
    title: 'Resellers',
    description: 'Offer our trading widgets to your customers and grow your business.',
    icon: Building2,
    benefits: [
      'Competitive commission rates',
      'Sales enablement resources',
      'Dedicated partner manager',
      'Training and certification'
    ]
  }
];

const featuredPartners: FeaturedPartner[] = [
  {
    id: '1',
    name: 'TradeTech Solutions',
    logo: '/partners/tradetech.png',
    description: 'Leading provider of algorithmic trading solutions.',
    type: 'Technology Partner',
    link: 'https://tradetech.com'
  },
  {
    id: '2',
    name: 'MarketData Pro',
    logo: '/partners/marketdata.png',
    description: 'Real-time market data and analytics provider.',
    type: 'Data Provider',
    link: 'https://marketdatapro.com'
  },
  {
    id: '3',
    name: 'Global Trading Systems',
    logo: '/partners/gts.png',
    description: 'Enterprise trading platform solutions.',
    type: 'Reseller Partner',
    link: 'https://gts.com'
  }
];

const Partners = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Hand className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Partner with Newsroom
            </h1>
            <p className={`text-xl max-w-3xl mx-auto mb-8 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Join our ecosystem of technology partners, data providers, and resellers
              to shape the future of trading technology.
            </p>
            <div className="flex justify-center space-x-4">
              <a
                href="/partners/apply"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Become a Partner
                <ArrowRight className="w-5 h-5 ml-2" />
              </a>
              <a
                href="/partners/directory"
                className={`inline-flex items-center px-6 py-3 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-800 text-white hover:bg-gray-700'
                    : 'bg-white text-gray-900 hover:bg-gray-50'
                } transition-colors`}
              >
                Partner Directory
                <Users className="w-5 h-5 ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Partnership Types */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-12 text-center ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Partnership Programs
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {partnerTypes.map((type) => {
            const Icon = type.icon;
            return (
              <div
                key={type.id}
                className={`p-6 rounded-xl border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700' 
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="w-12 h-12 rounded-lg bg-blue-600 flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-white" />
                </div>
                <h3 className={`text-xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {type.title}
                </h3>
                <p className={`mb-6 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {type.description}
                </p>
                <ul className="space-y-3">
                  {type.benefits.map((benefit, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle2 className={`w-5 h-5 mr-2 flex-shrink-0 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                      }`} />
                      <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                        {benefit}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>

      {/* Featured Partners */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-2xl font-bold mb-12 text-center ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Featured Partners
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {featuredPartners.map((partner) => (
              <a
                key={partner.id}
                href={partner.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`p-6 rounded-lg border group transition-colors ${
                  isDarkMode 
                    ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                    : 'bg-gray-50 border-gray-200 hover:border-blue-500'
                }`}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className={`text-sm font-medium ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-600'
                  }`}>
                    {partner.type}
                  </div>
                  <ExternalLink className="w-4 h-4 text-gray-400" />
                </div>
                <h3 className={`text-xl font-bold mb-3 group-hover:text-blue-500 transition-colors ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {partner.name}
                </h3>
                <p className={`${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {partner.description}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Integration Options */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-12 text-center ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Integration Options
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className={`p-6 rounded-lg border ${
            isDarkMode 
              ? 'bg-gray-800 border-gray-700' 
              : 'bg-white border-gray-200'
          }`}>
            <h3 className={`flex items-center text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              <Code className="w-6 h-6 mr-2 text-blue-500" />
              API Integration
            </h3>
            <p className={`mb-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Connect your systems directly with our API for seamless data exchange
              and widget customization.
            </p>
            <a
              href="/docs/api"
              className="inline-flex items-center text-blue-500 hover:text-blue-600"
            >
              View API Documentation
              <ArrowRight className="w-4 h-4 ml-1" />
            </a>
          </div>

          <div className={`p-6 rounded-lg border ${
            isDarkMode 
              ? 'bg-gray-800 border-gray-700' 
              : 'bg-white border-gray-200'
          }`}>
            <h3 className={`flex items-center text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              <Layers className="w-6 h-6 mr-2 text-blue-500" />
              Widget SDK
            </h3>
            <p className={`mb-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Embed our widgets directly into your application with our
              easy-to-use SDK.
            </p>
            <a
              href="/docs/sdk"
              className="inline-flex items-center text-blue-500 hover:text-blue-600"
            >
              Learn More About SDK
              <ArrowRight className="w-4 h-4 ml-1" />
            </a>
          </div>
        </div>
      </div>

      {/* Partner Stats */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div className="text-center">
              <Globe className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                50+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Countries
              </div>
            </div>
            <div className="text-center">
              <Building2 className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                200+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Partners
              </div>
            </div>
            <div className="text-center">
              <BarChart className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                1M+
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Widgets Deployed
              </div>
            </div>
            <div className="text-center">
              <BadgeCheck className={`w-8 h-8 mx-auto mb-4 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <div className={`text-3xl font-bold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                99.9%
              </div>
              <div className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                Uptime
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default Partners;