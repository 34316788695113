import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import {
  LineChart,
  Table,
  FormInput,
  Activity,
  Wand2,
  BarChart2,
  CandlestickChart,
  TrendingUp,
  History,
  BookOpen,
  Bot
} from 'lucide-react';
import { ChevronRight, Zap } from 'lucide-react';
import { Plus } from 'lucide-react';
import { PriceChartPreview, VolumeProfilePreview, TemplateCard, PreviewProps } from './WidgetPreviews';



interface WidgetTypeOption {
    id: string;
    title: string;
    description: string;
    icon: React.FC<{ className?: string }>;
    path: string;
    color: string;
  }
  
  interface Template {
    id: string;
    title: string;
    description: string;
    category: 'chart' | 'table' | 'form' | 'monitor';
    complexity: 'basic' | 'advanced' | 'pro';
    creditCost: number;
    PreviewComponent: React.FC<PreviewProps>;
  }

  const widgetTypes: WidgetTypeOption[] = [
    {
      id: 'chart',
      title: 'Chart Widget',
      description: 'Create interactive charts for data visualization',
      icon: LineChart,
      path: '/dashboard/create/chart',
      color: 'bg-blue-500 hover:bg-blue-600'
    },
    {
      id: 'table',
      title: 'Table Widget',
      description: 'Display and analyze data in a structured format',
      icon: Table,
      path: '/dashboard/create/table',
      color: 'bg-green-500 hover:bg-green-600'
    },
    {
      id: 'form',
      title: 'Form Widget',
      description: 'Create interactive input forms',
      icon: FormInput,
      path: '/dashboard/create/form',
      color: 'bg-purple-500 hover:bg-purple-600'
    },
    {
      id: 'monitor',
      title: 'Monitor Widget',
      description: 'Real-time data monitoring and alerts',
      icon: Activity,
      path: '/dashboard/create/monitor',
      color: 'bg-red-500 hover:bg-red-600'
    }
  ];
  

  

const WidgetCreationHub: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [complexity, setComplexity] = useState<string>('all');

  const handleTemplateSelect = (templateId: string) => {
    const template = templates.find(t => t.id === templateId);
    if (template) {
      if (template.category === 'chart') {
        navigate(`/dashboard/create/chart?template=${templateId}`);
      } else if (template.category === 'table') {
        navigate(`/dashboard/create/table?template=${templateId}`);
      } else if (template.category === 'form') {
        navigate(`/dashboard/create/form?template=${templateId}`);
      } else if (template.category === 'monitor') {
        navigate(`/dashboard/create/monitor?template=${templateId}`);
      }
    }
  };
  const templates: Template[] = [
    {
      id: 'price-chart',
      title: 'Price Chart',
      description: 'Interactive price chart with technical indicators and volume analysis',
      category: 'chart',
      complexity: 'advanced',
      creditCost: 50,
      PreviewComponent: PriceChartPreview,
    },
    {
      id: 'volume-profile',
      title: 'Volume Profile',
      description: 'Advanced volume analysis with price distributions and POC',
      category: 'chart',
      complexity: 'pro',
      creditCost: 75,
      PreviewComponent: VolumeProfilePreview,
    }
  ];
  

  const categories = [
    { id: 'all', label: 'All Templates', icon: BookOpen },
    { id: 'chart', label: 'Charts', icon: LineChart },
    { id: 'table', label: 'Tables', icon: Table },
    { id: 'form', label: 'Forms', icon: FormInput },
    { id: 'monitor', label: 'Monitors', icon: Activity }
  ];

  const filteredTemplates = templates.filter(template => {
  const matchesCategory = selectedCategory === 'all' || template.category === selectedCategory;
  const matchesComplexity = complexity === 'all' || template.complexity === complexity;
  const matchesSearch = template.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       template.description.toLowerCase().includes(searchQuery.toLowerCase());
  return matchesCategory && matchesComplexity && matchesSearch;
});

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    {/* Header */}
    <div className="flex items-center justify-between mb-8">
      <div>
        <h1 className={`text-2xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Create New Widget
        </h1>
        <p className={`mt-2 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          Choose how you want to create your widget
        </p>
      </div>

      {/* Quick Actions */}
      <div className="flex items-center space-x-4">
        <button
          onClick={() => navigate('/dashboard/create/ai')}
          className="flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700"
        >
          <Bot className="w-5 h-5 mr-2" />
          AI Generator
        </button>
      </div>
    </div>

    {/* Widget Type Grid */}
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
      {widgetTypes.map((type) => (
        <button
          key={type.id}
          onClick={() => navigate(type.path)}
          className={`p-6 rounded-xl ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          } shadow-lg hover:shadow-xl transition-all duration-300 text-left group relative`}
        >
          {/* Hover Effect */}
          <div className={`absolute inset-0 ${type.color} opacity-0 group-hover:opacity-10 rounded-xl transition-opacity`} />

          <div className="flex items-start space-x-4">
            {/* Icon */}
            <div className={`p-3 rounded-lg ${type.color} text-white`}>
              <type.icon className="w-6 h-6" />
            </div>

            {/* Content */}
            <div>
              <h3 className={`text-lg font-semibold mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {type.title}
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                {type.description}
              </p>

              {/* Create Button */}
              <div className={`mt-4 inline-flex items-center text-sm font-medium ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              } group-hover:translate-x-1 transition-transform`}>
                Create Widget
                <Plus className="w-4 h-4 ml-1" />
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>

    {/* AI Assistant Prompt */}
    <div className={`mt-8 p-6 rounded-xl ${
      isDarkMode ? 'bg-gray-800/50' : 'bg-blue-50'
    } border ${isDarkMode ? 'border-gray-700' : 'border-blue-200'}`}>
      <div className="flex items-start space-x-6">
        <div className="p-3 rounded-lg bg-blue-600 text-white">
          <Bot className="w-8 h-8" />
        </div>
        <div>
          <h3 className={`text-lg font-semibold mb-2 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Need help deciding?
          </h3>
          <p className={`text-sm mb-4 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Let our AI assistant help you create the perfect widget.
            Just describe what you want to build in plain English.
          </p>
          <button
            onClick={() => navigate('/dashboard/create/ai')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
          >
            <Bot className="w-4 h-4 mr-2" />
            Try AI Generator
          </button>
        </div>
      </div>
    </div>
  



      {/* Categories */}
      <div className={`border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} mt-1`}>
        <div className="flex space-x-8">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`flex items-center px-4 py-4 border-b-2 transition-colors ${
                selectedCategory === category.id
                  ? 'border-blue-500 text-blue-600'
                  : `border-transparent ${
                      isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-700'
                    }`
              }`}
            >
              <category.icon className="w-5 h-5 mr-2" />
              {category.label}
            </button>
          ))}
        </div>
      </div>

      {/* Search and Filters */}
      <div className="flex items-center space-x-4 mt-6">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search templates..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`w-full px-4 py-2 pl-10 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-800 text-white placeholder-gray-400' 
                : 'bg-white text-gray-900 placeholder-gray-500'
            } border ${
              isDarkMode ? 'border-gray-700' : 'border-gray-200'
            } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
          />
          <BookOpen className={`absolute left-3 top-3.5 w-4 h-4 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`} />
        </div>

        {/* Complexity Filter */}
        <select
        value={complexity}
        onChange={(e) => setComplexity(e.target.value)}
        className={`px-4 py-2 rounded-lg ${
            isDarkMode 
            ? 'bg-gray-800 text-white border-gray-700' 
            : 'bg-white text-gray-900 border-gray-200'
        } border focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
        >
        <option value="all">All Complexity</option>
        <option value="basic">Basic</option>
        <option value="advanced">Advanced</option>
        <option value="pro">Pro</option>
        </select>
      </div>

      {/* Template Grid */}
      {/* Template Grid */}
<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
  {filteredTemplates.map((template) => (
    <TemplateCard
      key={template.id}
      title={template.title}
      description={template.description}
      PreviewComponent={template.PreviewComponent}
      onClick={() => handleTemplateSelect(template.id)}
    />
  ))}
</div>

      
    </div>
  );
};

export default WidgetCreationHub;