// src/components/widgets/customization/shared/Section.tsx
import React from 'react';
import { LucideIcon, ChevronDown, ChevronRight } from 'lucide-react';
import { useTheme } from '../../../../contexts/ThemeContext';

export interface SectionProps {
  title: string;
  icon: LucideIcon;
  children: React.ReactNode;
  expanded: boolean;
  onToggle: () => void;
}

export const Section: React.FC<SectionProps> = ({
  title,
  icon: Icon,
  children,
  expanded,
  onToggle
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="border-b border-gray-200 dark:border-gray-700 last:border-0 pb-4">
      <button
        onClick={onToggle}
        className={`flex items-center w-full p-2 rounded-lg transition-colors ${
          isDarkMode
            ? 'hover:bg-gray-800 text-gray-200'
            : 'hover:bg-gray-100 text-gray-900'
        }`}
      >
        <Icon className="w-5 h-5 mr-2" />
        <span className="flex-1 text-left font-medium">{title}</span>
        {expanded ? (
          <ChevronDown className="w-5 h-5" />
        ) : (
          <ChevronRight className="w-5 h-5" />
        )}
      </button>
      {expanded && (
        <div className="mt-4 pl-4 space-y-4">
          {children}
        </div>
      )}
    </div>
  );
};