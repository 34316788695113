// src/pages/dashboard/Dashboard.tsx
import { Routes, Route } from 'react-router-dom';
import { DashboardLayout } from './DashboardLayout';
import WidgetCreationHub from './WidgetCreationHub';
import AIWidgetGenerator from './AIWidgetGenerator';
import { WidgetLibrary } from './WidgetLibrary';
import { WidgetSettings } from './WidgetSettings';
import { Credits } from './Credits';
import { WidgetCustomization } from './WidgetCustomization';
import ChartWidgetCreator from '../../components/widgets/ChartWidgetCreator';
import TableWidgetCreator from '../../components/widgets/TableWidgetCreator';
import FormWidgetCreator from '../../components/widgets/FormWidgetCreator';
import MonitorWidgetCreator from '../../components/widgets/MonitorWidgetCreator';
import MarketData from '../../components/MarketData';
export const Dashboard: React.FC = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<WidgetCreationHub />} />
        <Route path="create" element={<WidgetCreationHub />} />
        <Route path="create/ai" element={<AIWidgetGenerator />} />
        <Route path="create/chart" element={<ChartWidgetCreator />} />
        <Route path="create/table" element={<TableWidgetCreator />} />
        <Route path="create/form" element={<FormWidgetCreator />} />
        <Route path="create/monitor" element={<MonitorWidgetCreator />} />
        <Route path="library" element={<WidgetLibrary />} />
        <Route path="settings" element={<WidgetSettings />} />
        <Route path="credits" element={<Credits />} />
        <Route path="customize/:widgetId" element={<WidgetCustomization />} />
        <Route path="market" element={<MarketData />} />
      </Routes>
    </DashboardLayout>
  );
};