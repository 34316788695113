// src/types/widget.ts
import { 
  VisualSettings, 
  DataSettings, 
  ChartSettings,
  FontSize 
} from './shared';
import { VisualConfig, DataConfig, SortDirection } from './shared';
export enum WidgetType {
  CHART = 'CHART',
  TABLE = 'TABLE',
  FORM = 'FORM',
  MONITOR = 'MONITOR'
}

export interface ThemeConfig {
  colors: string[];
  fontFamily: string;
  fontSize: string;
  borderRadius: string;
  shadow: string;
}

// src/types/widget.ts (add to existing types)
export interface FormFieldConfig {
  name: string;
  type: 'text' | 'number' | 'select' | 'date';
  label: string;
  description?: string;
  required?: boolean;
  options?: string[];
}

export interface LayoutConfig {
  padding: number;
  gap: number;
  aspectRatio: string;
}

export interface AnimationConfig {
  enabled: boolean;
  type: string;
  duration: number;
}



export function isWidgetConfig(obj: any): obj is WidgetConfig {
  return (
    obj &&
    typeof obj === 'object' &&
    'widgetType' in obj &&
    Object.values(WidgetType).includes(obj.widgetType)
  );
}



export interface ChartAxisConfig {
  dataKey: string;
  label: string;
  tickRotation: number;
}

export interface ChartSpecificConfig {
  type: 'line' | 'bar' | 'area';
  metrics: string[];
  xAxis: ChartAxisConfig;
  yAxis: ChartAxisConfig;
  showLegend: boolean;
  showGrid: boolean;
  showTooltip: boolean;
  stacked: boolean;
  colors?: string[];
}

export interface WidgetConfigWithRefresh {
  data?: DataConfig & {
    autoRefresh?: boolean;
    refreshInterval?: number;
  };
}

export interface TableWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.TABLE;
  tableConfig: {
    filtering: boolean | undefined;
    columns: Array<{
      key: string;
      label: string;
      sortable?: boolean;
    }>;
    pagination?: boolean;
    pageSize?: number;
  };
}

export interface FormWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.FORM;
  formConfig: {
    fields: Array<{
      name: string;
      type: 'text' | 'number' | 'select' | 'date';
      label: string;
      required?: boolean;
      options?: string[];
    }>;
    submitLabel?: string;
  };
}

export interface MonitorWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.MONITOR;
  monitorConfig: {
    metrics: string[];
    threshold?: number;
    alertCondition?: 'above' | 'below' | 'equals';
    refreshInterval: number;
    autoRefresh: boolean;
  };
}


export interface Widget {
  id: string;
  name: string;
  widgetType: WidgetType;
  config: WidgetConfig;
  content?: {
    code?: string;
    data?: any;
  };
  createdAt: string;
  updatedAt: string;
}


export interface WidgetUpdateInput {
  name?: string;
  description?: string;
  config?: Partial<WidgetConfig>;
}

// Default configurations


export const DEFAULT_CHART_CONFIG: ChartSpecificConfig = {
  type: 'line',
  metrics: [],
  xAxis: {
    dataKey: 'x',
    label: 'X Axis',
    tickRotation: 0
  },
  yAxis: {
    dataKey: 'value',
    label: 'Value',
    tickRotation: 0
  },
  showLegend: true,
  showGrid: true,
  showTooltip: true,
  stacked: false,
  colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444']
};

export interface WidgetConfigBase {
  name: string;
  description?: string;
  function: string;
  presentation: string;
  visual?: VisualConfig;
  data?: DataConfig;
  inputs?: Record<string, any>;
}

export interface ChartWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.CHART;
  chartConfig: ChartSettings;
}

// Default configurations
export const DEFAULT_VISUAL_CONFIG: VisualSettings = {
  theme: {
    colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444'],
    fontFamily: 'Inter',
    fontSize: 'md' as FontSize,
    borderRadius: 'rounded-lg',
    shadow: 'shadow-md'
  },
  layout: {
    padding: 4,
    gap: 4,
    aspectRatio: '16/9'
  },
  animation: {
    enabled: true,
    type: 'fade',
    duration: 300
  }
};

export function isValidSorting(sort: string): sort is 'asc' | 'desc' {
  return ['asc', 'desc'].includes(sort);
}

export function ensureValidConfig<T extends WidgetConfig>(config: Partial<T>): T {
  if (!isWidgetConfig(config)) {
    throw new Error('Invalid widget configuration');
  }
  
  if (config.data?.sorting && !isValidSorting(config.data.sorting)) {
    config.data.sorting = 'asc';
  }

  return config as T;
}

export const DEFAULT_DATA_CONFIG: DataConfig = {
  aggregation: 'none',
  sorting: 'asc',
  filtering: [],
  transforms: [],
  autoRefresh: false,
  refreshInterval: 30,
  maxDataPoints: 100
};

export const DEFAULT_WIDGET_CONFIG = {
  visual: DEFAULT_VISUAL_CONFIG,
  data: DEFAULT_DATA_CONFIG
};


// Base configuration interface
export interface WidgetConfigBase {
  name: string;
  description?: string;
  function: string;
  presentation: string;
  visual?: VisualConfig;
  data?: DataConfig;
  inputs?: Record<string, any>;
}

// Chart specific interfaces
export interface ChartAxisConfig {
  dataKey: string;
  label: string;
  tickRotation: number;
}

export interface ChartSpecificConfig {
  type: 'line' | 'bar' | 'area';
  metrics: string[];
  xAxis: ChartAxisConfig;
  yAxis: ChartAxisConfig;
  showLegend: boolean;
  showGrid: boolean;
  showTooltip: boolean;
  stacked: boolean;
  colors?: string[];
}

export interface ChartWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.CHART;
  chartConfig: ChartSpecificConfig;
}

// Table specific interfaces
export interface TableColumn {
  key: string;
  label: string;
  sortable?: boolean;
}

export interface TableSpecificConfig {
  columns: TableColumn[];
  pagination?: boolean;
  pageSize?: number;
  filtering?: boolean;
}



// Form specific interfaces
export interface FormField {
  name: string;
  type: 'text' | 'number' | 'select' | 'date';
  label: string;
  required?: boolean;
  options?: string[];
}

export interface FormSpecificConfig {
  fields: FormField[];
  submitLabel?: string;
}

export interface FormWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.FORM;
  formConfig: FormSpecificConfig;
}

// Monitor specific interfaces
export interface MonitorSpecificConfig {
  metrics: string[];
  threshold?: number;
  alertCondition?: 'above' | 'below' | 'equals';
  refreshInterval: number;
  autoRefresh: boolean;
}

export interface MonitorWidgetConfig extends WidgetConfigBase {
  widgetType: WidgetType.MONITOR;
  monitorConfig: MonitorSpecificConfig;
}

// Union type for all widget configurations
export type WidgetConfig = 
  | ChartWidgetConfig 
  | TableWidgetConfig 
  | FormWidgetConfig 
  | MonitorWidgetConfig;

// Widget creation input type
export interface WidgetCreateInput {
  name: string;
  widgetType: WidgetType;
  config: WidgetConfig;
}

// Type guards
export function isChartWidget(config: WidgetConfig): config is ChartWidgetConfig {
  return config.widgetType === WidgetType.CHART;
}

export function isTableWidget(config: WidgetConfig): config is TableWidgetConfig {
  return config.widgetType === WidgetType.TABLE;
}

export function isFormWidget(config: WidgetConfig): config is FormWidgetConfig {
  return config.widgetType === WidgetType.FORM;
}

export function isMonitorWidget(config: WidgetConfig): config is MonitorWidgetConfig {
  return config.widgetType === WidgetType.MONITOR;
}