// src/components/widgets/customization/VisualSettings.tsx
import React, { useState } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { WidgetType, ChartSpecificConfig } from '../../../types/widget';
import { VisualConfig, ChartSettings } from '../../../types/shared';
import { Section } from './shared/Section';
import {
  Paintbrush,
  Type,
  Layout,
  BarChart,
  Plus,
  Trash2,
  Check,
  ChevronDown,
  ChevronRight
} from 'lucide-react';

interface VisualSettingsProps {
  settings: VisualConfig;
  onChange: (settings: Partial<VisualConfig>) => void;
  chartConfig?: ChartSpecificConfig;
  onChartSettingsChange?: (settings: Partial<ChartSpecificConfig>) => void;
  widgetType?: WidgetType;
}

export const VisualSettingsPanel: React.FC<VisualSettingsProps> = ({
  settings,
  onChange,
  chartConfig,
  onChartSettingsChange,
  widgetType
}) => {
  const { isDarkMode } = useTheme();
  const [expandedSections, setExpandedSections] = useState({
    theme: true,
    typography: true,
    layout: true,
    chart: true
  });

  const handleColorChange = (index: number, color: string) => {
    const newColors = [...settings.theme.colors];
    newColors[index] = color;
    onChange({
      ...settings,
      theme: {
        ...settings.theme,
        colors: newColors
      }
    });
  };

  const handleAddColor = () => {
    onChange({
      ...settings,
      theme: {
        ...settings.theme,
        colors: [...settings.theme.colors, '#000000']
      }
    });
  };

  const handleRemoveColor = (index: number) => {
    const newColors = settings.theme.colors.filter((_: string, i: number) => i !== index);
    onChange({
      ...settings,
      theme: {
        ...settings.theme,
        colors: newColors
      }
    });
  };

  const handleChartTypeChange = (type: 'line' | 'bar' | 'area') => {
    if (chartConfig && onChartSettingsChange) {
      onChartSettingsChange({ type });
    }
  };

  const handleChartSettingChange = (setting: Partial<ChartSpecificConfig>) => {
    if (onChartSettingsChange) {
      onChartSettingsChange(setting);
    }
  };

  return (
    <div className="space-y-6">
      <Section
        title="Theme"
        icon={Paintbrush}
        expanded={expandedSections.theme}
        onToggle={() => setExpandedSections(prev => ({ ...prev, theme: !prev.theme }))}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">Color Scheme</label>
            <div className="space-y-3">
              <div className="flex flex-wrap gap-2">
                {settings.theme.colors.map((color: string, index: number) => (
                  <div key={index} className="relative group">
                    <input
                      type="color"
                      value={color}
                      onChange={(e) => handleColorChange(index, e.target.value)}
                      className="w-8 h-8 rounded-lg cursor-pointer"
                    />
                    <button
                      onClick={() => handleRemoveColor(index)}
                      className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-white 
                      opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                  </div>
                ))}
                <button
                  onClick={handleAddColor}
                  className={`w-8 h-8 rounded-lg border-2 border-dashed ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-300'
                  } flex items-center justify-center`}
                >
                  <Plus className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section
        title="Typography"
        icon={Type}
        expanded={expandedSections.typography}
        onToggle={() => setExpandedSections(prev => ({ ...prev, typography: !prev.typography }))}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">Font Family</label>
            <select
              value={settings.theme.fontFamily}
              onChange={(e) => onChange({
                ...settings,
                theme: { ...settings.theme, fontFamily: e.target.value }
              })}
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-white border-gray-600' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
            >
              <option value="Inter">Inter</option>
              <option value="Roboto">Roboto</option>
              <option value="Poppins">Poppins</option>
            </select>
          </div>
        </div>
      </Section>

      <Section
        title="Layout"
        icon={Layout}
        expanded={expandedSections.layout}
        onToggle={() => setExpandedSections(prev => ({ ...prev, layout: !prev.layout }))}
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">Padding</label>
            <input
              type="range"
              min="0"
              max="8"
              value={settings.layout.padding}
              onChange={(e) => onChange({
                ...settings,
                layout: { ...settings.layout, padding: Number(e.target.value) }
              })}
              className="w-full"
            />
          </div>
        </div>
      </Section>

      {widgetType === WidgetType.CHART && chartConfig && (
        <Section
          title="Chart Settings"
          icon={BarChart}
          expanded={expandedSections.chart}
          onToggle={() => setExpandedSections(prev => ({ ...prev, chart: !prev.chart }))}
        >
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Show Legend</label>
              <div className="flex items-center justify-between">
                <input
                  type="checkbox"
                  checked={chartConfig.showLegend}
                  onChange={(e) => onChartSettingsChange && onChartSettingsChange({ showLegend: e.target.checked })}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Show Grid</label>
              <div className="flex items-center justify-between">
                <input
                  type="checkbox"
                  checked={chartConfig.showGrid}
                  onChange={(e) => onChartSettingsChange && onChartSettingsChange({ showGrid: e.target.checked })}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Show Tooltip</label>
              <div className="flex items-center justify-between">
                <input
                  type="checkbox"
                  checked={chartConfig.showTooltip}
                  onChange={(e) => onChartSettingsChange && onChartSettingsChange({ showTooltip: e.target.checked })}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Stacked</label>
              <div className="flex items-center justify-between">
                <input
                  type="checkbox"
                  checked={chartConfig.stacked}
                  onChange={(e) => onChartSettingsChange && onChartSettingsChange({ stacked: e.target.checked })}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        </Section>
      )}
    </div>
  );
};

export default VisualSettingsPanel;