import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { useTheme } from '../../contexts/ThemeContext';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { Alert } from '../../components/ui/alert';
import { Send, Bot } from 'lucide-react';
import { claudeService } from '../../services/claudeService';
import { 
  WidgetType,
  DEFAULT_VISUAL_CONFIG,
  DEFAULT_DATA_CONFIG,
  DEFAULT_CHART_CONFIG,
  ChartWidgetConfig 
} from '../../types/widget';

const AIWidgetGenerator = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { createWidget, isLoading, error: widgetError } = useWidget();
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    try {
      setError(null);
      
      const chartConfig: ChartWidgetConfig = {
        name: 'AI Generated Widget',
        description: 'Generated from AI prompt',
        widgetType: WidgetType.CHART,
        function: prompt,
        presentation: '',
        visual: DEFAULT_VISUAL_CONFIG,
        data: DEFAULT_DATA_CONFIG,
        inputs: {},
        chartConfig: DEFAULT_CHART_CONFIG
      };

      // Generate widget code using Claude
      const response = await claudeService.generateWidgetCode({
        widgetType: WidgetType.CHART,
        config: chartConfig
      });

      // Create the widget - only passing the required properties
      await createWidget({
        name: 'AI Generated Widget',
        widgetType: WidgetType.CHART,
        config: chartConfig
      });

      navigate('/dashboard/library');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate widget');
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className={`p-6 rounded-xl ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg`}>
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className={`text-2xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              AI Widget Generator
            </h2>
            <p className={`mt-2 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Describe your desired widget in plain text and our AI will create it
            </p>
          </div>
          <Bot className={`w-8 h-8 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-600'
          }`} />
        </div>

        {(error || widgetError) && (
          <Alert variant="destructive" className="mb-6">
            {error || widgetError}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <div className="relative">
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Example: Create a line chart showing stock prices over time with volume bars below..."
              rows={6}
              className={`w-full px-4 py-3 rounded-lg resize-none ${
                isDarkMode 
                  ? 'bg-gray-700 text-white placeholder-gray-400' 
                  : 'bg-gray-50 text-gray-900 placeholder-gray-500'
              } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            />
            
            <button
              type="submit"
              disabled={isLoading || !prompt.trim()}
              className={`absolute bottom-4 right-4 p-2 rounded-lg transition-colors ${
                prompt.trim()
                  ? 'text-blue-500 hover:bg-blue-50 dark:hover:bg-gray-600'
                  : 'text-gray-400 cursor-not-allowed'
              }`}
            >
              {isLoading ? (
                <LoadingSpinner size="sm" />
              ) : (
                <Send className="w-5 h-5" />
              )}
            </button>
          </div>

          {/* Example Prompts */}
          <div className="mt-6">
            <h3 className={`text-sm font-medium mb-3 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              Example Prompts
            </h3>
            <div className="flex flex-wrap gap-2">
              {[
                "Line chart with price and volume",
                "Real-time stock monitor",
                "Trading form with validation",
                "Market depth table"
              ].map((example, i) => (
                <button
                  key={i}
                  type="button"
                  onClick={() => setPrompt(example)}
                  className={`text-sm px-3 py-1.5 rounded-full ${
                    isDarkMode
                      ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  } transition-colors`}
                >
                  {example}
                </button>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AIWidgetGenerator;