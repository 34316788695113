// src/hooks/useWidget.ts
import { useState, useEffect, useCallback } from 'react';
import { Widget, WidgetCreateInput, WidgetUpdateInput } from '../types/widget';
import { widgetService } from '../services/widgetService';

export function useWidget(widgetId?: string) {
  const [widget, setWidget] = useState<Widget | null>(null);
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateContent = async (id: string, inputs: Record<string, any>): Promise<Widget> => {
    try {
      setIsLoading(true);
      const responseData = await widgetService.generateContent(id, inputs);
      
      // Update widgets state
      setWidgets(prev => prev.map(w => w.id === id ? responseData : w));
      
      // Update widget state if it matches the current widget
      if (widget?.id === id) {
        setWidget(responseData);
      }
      
      setError(null);
      return responseData;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to generate content';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const createWidget = async (input: WidgetCreateInput): Promise<Widget> => {
    try {
      setIsLoading(true);
      const data = await widgetService.createWidget(input);
      setWidgets(prev => [...prev, data]);
      setError(null);
      return data;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to create widget';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const updateWidget = async (id: string, input: WidgetUpdateInput): Promise<Widget> => {
    try {
      setIsLoading(true);
      const data = await widgetService.updateWidget(id, input);
      setWidgets(prev => prev.map(w => w.id === id ? data : w));
      if (widget?.id === id) {
        setWidget(data);
      }
      setError(null);
      return data;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to update widget';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWidget = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      await widgetService.deleteWidget(id);
      setWidgets(prev => prev.filter(w => w.id !== id));
      if (widget?.id === id) {
        setWidget(null);
      }
      setError(null);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete widget';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWidgets = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await widgetService.getUserWidgets();
      setWidgets(data);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch widgets');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (widgetId) {
      const fetchWidget = async () => {
        try {
          setIsLoading(true);
          const data = await widgetService.getWidget(widgetId);
          setWidget(data);
          setError(null);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to fetch widget');
        } finally {
          setIsLoading(false);
        }
      };
      fetchWidget();
    } else {
      fetchWidgets();
    }
  }, [widgetId, fetchWidgets]);

  return {
    widget,
    widgets,
    isLoading,
    error,
    generateContent,
    createWidget,
    updateWidget,
    deleteWidget,
    refetch: widgetId ? 
      async () => {
        const data = await widgetService.getWidget(widgetId);
        setWidget(data);
        return data;
      } : 
      fetchWidgets
  };
}

export default useWidget;