import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { useTheme } from '../../contexts/ThemeContext';
import { 
  WidgetType,
  TableWidgetConfig,
  DEFAULT_VISUAL_CONFIG,
  DEFAULT_DATA_CONFIG
} from '../../types/widget';
import { creditService } from '../../services/creditService';
import { 
  Table as TableIcon,
  Plus,
  Trash2,
  Sparkles,
  AlertCircle,
  SlidersHorizontal,
  Filter,
  ArrowUpDown
} from 'lucide-react';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { Button } from '../ui/button';

const TableWidgetCreator: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { createWidget, isLoading, error: serviceError } = useWidget();
  const [error, setError] = useState<string | null>(null);
  const [creditCost, setCreditCost] = useState(0);

  const [widgetConfig, setWidgetConfig] = useState<TableWidgetConfig>({
    name: '',
    description: '',
    widgetType: WidgetType.TABLE,
    function: '',
    presentation: '',
    tableConfig: {
      columns: [],
      pagination: true,
      pageSize: 10,
      filtering: true
    },
    visual: DEFAULT_VISUAL_CONFIG,
    data: DEFAULT_DATA_CONFIG,
    inputs: {}
  });

  useEffect(() => {
    const cost = creditService.calculateWidgetCost(
      widgetConfig.function,
      WidgetType.TABLE
    );
    setCreditCost(cost);
  }, [widgetConfig.function]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      await createWidget({
        name: widgetConfig.name,
        widgetType: WidgetType.TABLE,
        config: widgetConfig
      });
      navigate('/dashboard/library');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create widget');
    }
  };

  const handleColumnAdd = () => {
    setWidgetConfig(prev => ({
      ...prev,
      tableConfig: {
        ...prev.tableConfig,
        columns: [
          ...prev.tableConfig.columns,
          { key: '', label: '', sortable: true }
        ]
      }
    }));
  };

  const handleColumnRemove = (index: number) => {
    setWidgetConfig(prev => ({
      ...prev,
      tableConfig: {
        ...prev.tableConfig,
        columns: prev.tableConfig.columns.filter((_, i) => i !== index)
      }
    }));
  };

  const handleColumnChange = (index: number, field: string, value: any) => {
    setWidgetConfig(prev => ({
      ...prev,
      tableConfig: {
        ...prev.tableConfig,
        columns: prev.tableConfig.columns.map((col, i) => 
          i === index ? { ...col, [field]: value } : col
        )
      }
    }));
  };

  const inputClasses = `w-full rounded-lg px-4 py-2 ${
    isDarkMode 
      ? 'bg-gray-800 border-gray-700 text-white' 
      : 'bg-white border-gray-200 text-gray-900'
  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`;

  const labelClasses = `block text-sm font-medium ${
    isDarkMode ? 'text-gray-200' : 'text-gray-700'
  } mb-2`;

  return (
    <div className="max-w-3xl mx-auto">
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg p-6`}>
        <div className="flex items-center justify-between mb-6">
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Create Table Widget
          </h2>
          <div className="flex items-center space-x-2 text-blue-500">
            <Sparkles className="w-5 h-5" />
            <span>Cost: {creditCost} credits</span>
          </div>
        </div>

        {(error || serviceError) && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="w-4 h-4 mr-2" />
            {error || serviceError}
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Basic Info */}
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className={labelClasses}>
                Widget Name
              </label>
              <input
                id="name"
                value={widgetConfig.name}
                onChange={(e) => setWidgetConfig(prev => ({ 
                  ...prev, 
                  name: e.target.value 
                }))}
                className={inputClasses}
                placeholder="Enter widget name..."
              />
            </div>

            <div>
              <label htmlFor="description" className={labelClasses}>
                Description
              </label>
              <textarea
                id="description"
                value={widgetConfig.description}
                onChange={(e) => setWidgetConfig(prev => ({ 
                  ...prev, 
                  description: e.target.value 
                }))}
                className={inputClasses}
                rows={2}
                placeholder="Describe your widget..."
              />
            </div>
          </div>

          {/* Column Configuration */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <label className={labelClasses}>
                Table Columns
              </label>
              <Button
                type="button"
                onClick={handleColumnAdd}
                className="flex items-center text-sm"
              >
                <Plus className="w-4 h-4 mr-1" />
                Add Column
              </Button>
            </div>

            <div className="space-y-3">
              {widgetConfig.tableConfig.columns.map((column, index) => (
                <div 
                  key={index}
                  className={`p-4 rounded-lg border ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}
                >
                  <div className="flex justify-between items-center mb-3">
                    <span className={`text-sm font-medium ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Column {index + 1}
                    </span>
                    <button
                      type="button"
                      onClick={() => handleColumnRemove(index)}
                      className="text-red-500 hover:text-red-600"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <input
                        placeholder="Key"
                        value={column.key}
                        onChange={(e) => handleColumnChange(index, 'key', e.target.value)}
                        className={inputClasses}
                      />
                    </div>
                    <div>
                      <input
                        placeholder="Label"
                        value={column.label}
                        onChange={(e) => handleColumnChange(index, 'label', e.target.value)}
                        className={inputClasses}
                      />
                    </div>
                  </div>

                  <div className="mt-2 flex items-center space-x-4">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={column.sortable}
                        onChange={(e) => handleColumnChange(
                          index,
                          'sortable',
                          e.target.checked
                        )}
                        className="rounded border-gray-300"
                      />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        Sortable
                      </span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Features */}
          <div className="space-y-4">
            <h3 className={`text-lg font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Table Features
            </h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={widgetConfig.tableConfig.pagination}
                  onChange={(e) => setWidgetConfig(prev => ({
                    ...prev,
                    tableConfig: {
                      ...prev.tableConfig,
                      pagination: e.target.checked
                    }
                  }))}
                  className="rounded border-gray-300"
                />
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  Enable Pagination
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={widgetConfig.tableConfig.filtering}
                  onChange={(e) => setWidgetConfig(prev => ({
                    ...prev,
                    tableConfig: {
                      ...prev.tableConfig,
                      filtering: e.target.checked
                    }
                  }))}
                  className="rounded border-gray-300"
                />
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  Enable Filtering
                </span>
              </div>
            </div>

            {widgetConfig.tableConfig.pagination && (
              <div>
                <label className={labelClasses}>
                  Page Size
                </label>
                <input
                  type="number"
                  value={widgetConfig.tableConfig.pageSize}
                  onChange={(e) => setWidgetConfig(prev => ({
                    ...prev,
                    tableConfig: {
                      ...prev.tableConfig,
                      pageSize: parseInt(e.target.value)
                    }
                  }))}
                  min={1}
                  className={inputClasses}
                />
              </div>
            )}
          </div>

          {/* Function and Presentation */}
          <div className="space-y-4">
            <div>
              <label htmlFor="function" className={labelClasses}>
                Widget Function
              </label>
              <textarea
                id="function"
                value={widgetConfig.function}
                onChange={(e) => setWidgetConfig(prev => ({
                  ...prev,
                  function: e.target.value
                }))}
                className={inputClasses}
                rows={3}
                placeholder="Describe what your widget should do..."
              />
            </div>

            <div>
              <label htmlFor="presentation" className={labelClasses}>
                Presentation Style
              </label>
              <textarea
                id="presentation"
                value={widgetConfig.presentation}
                onChange={(e) => setWidgetConfig(prev => ({
                  ...prev,
                  presentation: e.target.value
                }))}
                className={inputClasses}
                rows={3}
                placeholder="Describe how your widget should look..."
              />
            </div>
          </div>

          {/* Submit */}
          <div className="flex justify-end">
            <Button
              type="submit"
              disabled={isLoading}
              className="flex items-center"
            >
              {isLoading ? (
                <>
                  <LoadingSpinner size="sm" className="mr-2" />
                  Creating...
                </>
              ) : (
                <>
                  <Sparkles className="w-4 h-4 mr-2" />
                  Create Widget
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TableWidgetCreator;