// components/widgets/WidgetContent.tsx

import React from 'react';
import { 
  LineChart, 
  BarChart, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  Line, 
  Bar, 
  ResponsiveContainer,
  Area,
  AreaChart
} from 'recharts';
import { 
  Widget, 
  WidgetType,
  ChartSpecificConfig,
  isChartWidget,
  isTableWidget,
  isFormWidget,
  isMonitorWidget
} from '../../types/widget';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';

interface WidgetContentProps {
  widget: Widget;
  isLoading?: boolean;
  error?: string | null;
}

interface ChartWidgetProps {
  config: ChartSpecificConfig;
  data: any[];
}

const ChartWidget: React.FC<ChartWidgetProps> = ({ config, data }) => {
  const getChartComponent = () => {
    switch (config.type) {
      case 'bar':
        return BarChart;
      case 'area':
        return AreaChart;
      case 'line':
      default:
        return LineChart;
    }
  };

  const ChartComponent = getChartComponent();
  
  // Ensure data is an array
  const chartData = Array.isArray(data) ? data : [];

  const renderChartElement = (metric: string, index: number) => {
    switch (config.type) {
      case 'bar':
        return (
          <Bar
            key={metric}
            dataKey={metric}
            fill={config.colors?.[index] || `#${Math.floor(Math.random()*16777215).toString(16)}`}
            stackId={config.stacked ? 'stack' : undefined}
          />
        );
      case 'area':
        return (
          <Area
            key={metric}
            type="monotone"
            dataKey={metric}
            stroke={config.colors?.[index]}
            fill={config.colors?.[index]}
            fillOpacity={0.2}
            stackId={config.stacked ? 'stack' : undefined}
          />
        );
      case 'line':
      default:
        return (
          <Line
            key={metric}
            type="monotone"
            dataKey={metric}
            stroke={config.colors?.[index]}
            dot={{ r: 4 }}
            activeDot={{ r: 6 }}
          />
        );
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ChartComponent data={chartData}>
        {config.showGrid && (
          <CartesianGrid strokeDasharray="3 3" />
        )}
        <XAxis
          dataKey={config.xAxis.dataKey}
          label={{ 
            value: config.xAxis.label,
            position: 'bottom',
            offset: 0
          }}
          angle={config.xAxis.tickRotation}
        />
        <YAxis
          label={{ 
            value: config.yAxis.label,
            angle: -90,
            position: 'insideLeft'
          }}
          tick={{
            transform: `rotate(${config.yAxis.tickRotation})`
          }}
        />
        {config.showTooltip && (
          <Tooltip 
            contentStyle={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }} 
          />
        )}
        {config.showLegend && (
          <Legend 
            verticalAlign="top"
            height={36}
          />
        )}
        {config.metrics.map((metric, index) => renderChartElement(metric, index))}
      </ChartComponent>
    </ResponsiveContainer>
  );
};

const TableWidget: React.FC<{ widget: Widget }> = ({ widget }) => {
  return <div>Table Widget - Coming Soon</div>;
};

const FormWidget: React.FC<{ widget: Widget }> = ({ widget }) => {
  return <div>Form Widget - Coming Soon</div>;
};

const MonitorWidget: React.FC<{ widget: Widget }> = ({ widget }) => {
  return <div>Monitor Widget - Coming Soon</div>;
};

export const WidgetContent: React.FC<WidgetContentProps> = ({ 
  widget, 
  isLoading, 
  error 
}) => {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        {error}
      </Alert>
    );
  }

  try {
    if (isChartWidget(widget.config)) {
      return (
        <ChartWidget 
          config={widget.config.chartConfig}
          data={widget.content?.data || []}
        />
      );
    }

    if (isTableWidget(widget.config)) {
      return <TableWidget widget={widget} />;
    }

    if (isFormWidget(widget.config)) {
      return <FormWidget widget={widget} />;
    }

    if (isMonitorWidget(widget.config)) {
      return <MonitorWidget widget={widget} />;
    }

    return (
      <Alert>Unsupported widget type: {widget.widgetType}</Alert>
    );
  } catch (err) {
    return (
      <Alert variant="destructive">
        Error rendering widget: {err instanceof Error ? err.message : 'Unknown error'}
      </Alert>
    );
  }
};

export default WidgetContent;