// client/src/App.tsx
import React from 'react';
import { AppRouter } from './router/AppRouter';
import { AuthProvider } from './contexts/AuthContext';

import ThemeProvider from './contexts/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
