import api from './api';
import { ChartSpecificConfig, FormWidgetConfig, MonitorWidgetConfig, TableWidgetConfig, Widget, WidgetConfigBase, WidgetType } from '../types/widget';
import { AIMessage } from '../types/shared';

interface GenerateParams {
  prompt: string;
  context?: AIMessage[];
  temperature?: number;
}

interface GenerateResponse {
  content: string;
  metadata?: {
    tokens_used: number;
    completion_tokens: number;
    prompt_tokens: number;
  };
}

export interface GenerateWidgetCodeParams {
  widgetType: WidgetType;
  config: WidgetConfigBase;
}


export class ClaudeService {
  private static instance: ClaudeService;
  private readonly MODEL = "claude-3-haiku-20240307"; // Add specific model version
  private readonly defaultSystemPrompt = `You are a React widget code generator. Generate clean, production-ready TypeScript code for React widgets.
Important instructions:
- Return ONLY the complete code file
- Do NOT include any explanations or markdown
- Include all necessary imports
- Include all type definitions
- Export the component as default
- Use Tailwind CSS for styling
- Handle loading and error states
- Make components responsive
- Include proper accessibility attributes
- Follow React best practices`;


  private constructor() {}

  static getInstance(): ClaudeService {
    if (!ClaudeService.instance) {
      ClaudeService.instance = new ClaudeService();
    }
    return ClaudeService.instance;
  }

  

  public async generateWidgetCode(params: GenerateWidgetCodeParams): Promise<string> {
    const prompt = this.buildGenerationPrompt(params);
    
    try {
      const { data } = await api.post<{ content: string }>('/claude/generate', {
        model: this.MODEL,
        prompt,
        system: this.defaultSystemPrompt,
        temperature: 0.3
      });

      return this.processGeneratedCode(data.content);
    } catch (error) {
      console.error('Widget code generation failed:', error);
      throw new Error('Failed to generate widget code');
    }
  }
  async generateWidgetRefinement(
    widget: Widget,
    instruction: string,
    context: AIMessage[]
  ): Promise<string> {
    try {
      const { data } = await api.post<{ content: string }>('/claude/refine', {
        model: this.MODEL,
        prompt: this.buildRefinementPrompt(widget, instruction),
        context: [
          ...context,
          { role: 'user', content: instruction }
        ],
        system: this.defaultSystemPrompt,
        temperature: 0.3
      });

      return this.processRefinementResponse(data.content);
    } catch (error) {
      console.error('Widget refinement failed:', error);
      throw new Error('Failed to refine widget');
    }
  }
  async generateDataTransformation(
    data: any[],
    instruction: string
  ): Promise<any[]> {
    const prompt = this.buildDataTransformPrompt(data, instruction);

    try {
      const { data: response } = await api.post<GenerateResponse>('/claude/transform', {
        prompt,
        system: 'You are a data transformation expert. Generate clean, efficient transformations.',
        temperature: 0.1
      });

      return this.parseTransformedData(response.content);
    } catch (error) {
      throw this.handleError(error, 'Data transformation failed');
    }
  }

  private buildGenerationPrompt(params: GenerateWidgetCodeParams): string {
    let prompt = `Generate a single React TypeScript file for a widget with these specifications. Return only the complete code without any explanation, markdown, or additional text:
  
  Widget Requirements:
  Type: ${params.widgetType}
  Function: ${params.config.function || 'Not specified'}
  Presentation: ${params.config.presentation || 'Default'}
  
  `;
  
    switch (params.widgetType) {
      case WidgetType.CHART:
        const chartConfig = params.config as unknown as { chartConfig: ChartSpecificConfig };
        if (chartConfig.chartConfig) {
          prompt += `Chart Configuration:
  Type: ${chartConfig.chartConfig.type}
  Metrics: ${chartConfig.chartConfig.metrics.join(', ') || 'None specified'}
  X-Axis: ${chartConfig.chartConfig.xAxis.dataKey} (${chartConfig.chartConfig.xAxis.label})
  Y-Axis: ${chartConfig.chartConfig.yAxis.dataKey} (${chartConfig.chartConfig.yAxis.label})
  Features: ${[
    chartConfig.chartConfig.showLegend && 'legend',
    chartConfig.chartConfig.showGrid && 'grid',
    chartConfig.chartConfig.showTooltip && 'tooltip',
    chartConfig.chartConfig.stacked && 'stacked'
  ].filter(Boolean).join(', ')}`;
        }
        break;
      // ... other widget types remain the same
    }
  
    prompt += `
  
  Technical Requirements:
  1. Use TypeScript for strong typing
  2. Use Recharts for chart implementation
  3. Use Tailwind CSS for styling
  4. Include error handling and loading states
  5. Make the component responsive
  6. Include proper accessibility attributes
  7. Export the component as default
  
  Return a single, complete React TypeScript file with all imports and type definitions. Include only the code, no explanations or markdown.`;
  
    return prompt;
  }

  private buildChartPrompt(config: ChartSpecificConfig): string {
    return `Chart Configuration:
- Type: ${config.type}
- Metrics: ${config.metrics.join(', ') || 'None specified'}
- X-Axis: ${config.xAxis.dataKey} (${config.xAxis.label})
- Y-Axis: ${config.yAxis.dataKey} (${config.yAxis.label})
- Features: ${[
  config.showLegend && 'legend',
  config.showGrid && 'grid',
  config.showTooltip && 'tooltip',
  config.stacked && 'stacked'
].filter(Boolean).join(', ')}`;
  }

  private buildDefaultChartPrompt(): string {
    return `Chart Configuration:
- Type: line
- Metrics: ['value']
- X-Axis: time (Time)
- Y-Axis: value (Value)
- Features: legend, grid, tooltip`;
  }

  private buildTablePrompt(config: any): string {
    if (!config) return 'Table Configuration: Default table with sorting and filtering';
    
    return `Table Configuration:
- Columns: ${config.columns?.map((col: any) => 
      `${col.key} (${col.label})`
    ).join(', ')}
- Features: ${[
      config.pagination && 'pagination',
      config.sorting && 'sorting',
      config.filtering && 'filtering'
    ].filter(Boolean).join(', ')}`;
  }

  private buildFormPrompt(config: any): string {
    if (!config) return 'Form Configuration: Default form with standard validation';
    
    return `Form Configuration:
- Fields: ${config.fields?.map((field: any) =>
      `${field.name} (${field.type}${field.required ? ', required' : ''})`
    ).join(', ')}
- Submit Label: ${config.submitLabel || 'Submit'}`;
  }

  private buildMonitorPrompt(config: any): string {
    if (!config) return 'Monitor Configuration: Default real-time monitor';
    
    return `Monitor Configuration:
- Metrics: ${config.metrics?.join(', ') || 'None specified'}
- Refresh Interval: ${config.refreshInterval || 30}s
- Alert Condition: ${config.alertCondition || 'none'} ${config.threshold || ''}`;
  }


  private buildRefinementPrompt(widget: Widget, instruction: string): string {
    return `Refine the following widget based on the instruction:

Current Widget Configuration:
${JSON.stringify(widget.config, null, 2)}

Instruction: ${instruction}

Please provide the refinements as a JSON object with the following structure:
{
  "visual": {
    // Visual customization changes
  },
  "data": {
    // Data handling changes
  },
  "chartConfig": {
    // Chart-specific changes (if applicable)
  }
}`;
  }

  private buildDataTransformPrompt(data: any[], instruction: string): string {
    return `Transform the following data according to the instruction:

Data:
${JSON.stringify(data, null, 2)}

Instruction: ${instruction}

Return the transformed data as a valid JSON array.`;
  }

  

  private processGeneratedCode(content: string): string {
    // Extract code from response, removing any markdown or explanations
    const codeMatch = content.match(/(?:```(?:typescript|tsx)?\n)?([\s\S]*?)(?:```)?$/);
    if (!codeMatch) {
      throw new Error('No valid code found in response');
    }
  
    // Clean up the extracted code
    let code = codeMatch[1].trim();
  
    // If code doesn't start with imports, add necessary ones
    if (!code.startsWith('import')) {
      code = `import React from 'react';
  import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
  
  ${code}`;
    }
  
    // Ensure code ends with export default
    if (!code.includes('export default')) {
      const componentName = code.match(/const\s+(\w+)/)?.[1];
      if (componentName) {
        code += `\n\nexport default ${componentName};`;
      }
    }
  
    return code;
  }

  private processRefinementResponse(content: string): string {
    try {
      // Extract JSON object from the response
      const jsonMatch = content.match(/\{[\s\S]*\}/);
      if (!jsonMatch) throw new Error('No valid JSON found in response');

      // Validate the JSON structure
      const refinements = JSON.parse(jsonMatch[0]);
      return JSON.stringify(refinements, null, 2);
    } catch (error) {
      throw new Error('Failed to process refinement response');
    }
  }

  private parseTransformedData(content: string): any[] {
    try {
      // Extract JSON array from the response
      const jsonMatch = content.match(/\[[\s\S]*\]/);
      if (!jsonMatch) throw new Error('No valid JSON array found in response');

      return JSON.parse(jsonMatch[0]);
    } catch (error) {
      throw new Error('Failed to parse transformed data');
    }
  }

  private handleError(error: unknown, defaultMessage: string): Error {
    if (error instanceof Error) {
      return error;
    }
    return new Error(defaultMessage);
  }
}

export const claudeService = ClaudeService.getInstance();