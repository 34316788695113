// client/src/pages/dashboard/WidgetLibrary.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { ErrorAlert } from '../../components/common/ErrorAlert';
import { WidgetCard } from '../../components/widgets/WidgetCard';
import { Plus } from 'lucide-react';
import { Alert } from '../../components/ui/alert';


export const WidgetLibrary: React.FC = () => {
  const { widgets, isLoading, error, deleteWidget, refetch } = useWidget();
  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await deleteWidget(id);
      await refetch();
    } catch (err) {
      console.error('Failed to delete widget:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <Alert variant="destructive">{error}</Alert>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Widget Library</h2>
        <button
          onClick={() => navigate('/dashboard')}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          New Widget
        </button>
      </div>

      {error && (
        <ErrorAlert message={error} onClose={() => {}} />
      )}

      {widgets.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <div className="flex justify-center">
            <div className="h-12 w-12 text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
              </svg>
            </div>
          </div>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No widgets</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new widget.
          </p>
          <div className="mt-6">
            <button
              onClick={() => navigate('/dashboard')}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
            >
              <Plus className="h-5 w-5 mr-2" />
              New Widget
            </button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {widgets.map(widget => (
        <WidgetCard
          key={widget.id}
          widget={widget}
          onDelete={() => handleDelete(widget.id)}
        />
      ))}
    </div>
      )}
    </div>
  );
};