// src/pages/BetaRequest.tsx
import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { BetaRequestInput } from '../types/beta';
import { Mail, Building2, Users, MessageSquare, ArrowRight, CheckCircle2, Loader2 } from 'lucide-react';
import { api } from '../services/api';

export const BetaRequest: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState<BetaRequestInput>({
    email: '',
    role: '',
    company: '',
    reason: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await api.post('/beta/request', formData);
      setIsSubmitted(true);
    } catch (err) {
      setError('Failed to submit beta request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center px-4">
        <div className={`max-w-md w-full p-8 rounded-2xl ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        } shadow-xl`}>
          <div className="text-center">
            <CheckCircle2 className="w-16 h-16 mx-auto mb-6 text-green-500" />
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Request Received!
            </h2>
            <p className={`${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            } mb-6`}>
              Thanks for your interest in Newsroom! We'll review your request and send your beta access code to {formData.email}.
            </p>
            
              href="/"
              className="inline-flex items-center text-blue-500 hover:text-blue-600"
            <a
              href="/"
              className="inline-flex items-center text-blue-500 hover:text-blue-600"
            >
              Return to homepage
              <ArrowRight className="w-4 h-4 ml-1" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className={`max-w-md w-full p-8 rounded-2xl ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-xl`}>
        <div className="text-center mb-8">
          <h2 className={`text-2xl font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Request Beta Access
          </h2>
          <p className={`mt-2 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Join the next generation of trading technology
          </p>
        </div>

        {error && (
          <div className="mb-6 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className={`block text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Email
            </label>
            <div className="relative">
              <Mail className={`absolute left-3 top-3 w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <input
                type="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className={`w-full pl-10 pr-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
                placeholder="you@company.com"
              />
            </div>
          </div>

          <div>
            <label className={`block text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Role
            </label>
            <div className="relative">
              <Users className={`absolute left-3 top-3 w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <select
                value={formData.role}
                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                className={`w-full pl-10 pr-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
              >
                <option value="">Select your role</option>
                <option value="developer">Developer</option>
                <option value="trader">Trader</option>
                <option value="analyst">Analyst</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div>
            <label className={`block text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Company
            </label>
            <div className="relative">
              <Building2 className={`absolute left-3 top-3 w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <input
                type="text"
                value={formData.company}
                onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                className={`w-full pl-10 pr-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
                placeholder="Company name"
              />
            </div>
          </div>

          <div>
            <label className={`block text-sm font-medium mb-2 ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              How will you use Newsroom?
            </label>
            <div className="relative">
              <MessageSquare className={`absolute left-3 top-3 w-5 h-5 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <textarea
                value={formData.reason}
                onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
                rows={4}
                className={`w-full pl-10 pr-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500`}
                placeholder="Tell us about your use case..."
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex items-center justify-center px-4 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50"
          >
            {isSubmitting ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <>
                Request Access
                <ArrowRight className="w-5 h-5 ml-2" />
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};