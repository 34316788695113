// src/components/widgets/customization/ChartSettings.tsx
import React, { useState } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { ChartSpecificConfig } from '../../../types/widget';
import { Section } from './shared/Section';
import { 
  BarChart2, 
  LineChart as LineChartIcon, 
  TrendingUp,
  Grid,
  Type,
  PieChart,
  ArrowLeftRight,
  ArrowUpDown,
  Plus,
  Trash2,
  Eye,
  Layers
} from 'lucide-react';

interface ChartSettingsProps {
    settings: ChartSpecificConfig;
    onChange: (settings: Partial<ChartSpecificConfig>) => void;
  }

const chartTypes = [
  { value: 'line', label: 'Line Chart', icon: LineChartIcon },
  { value: 'bar', label: 'Bar Chart', icon: BarChart2 },
  { value: 'area', label: 'Area Chart', icon: TrendingUp }
] as const;

export const ChartSettings: React.FC<ChartSettingsProps> = ({
    settings,
    onChange
  }) => {
    const { isDarkMode } = useTheme();
    const [expandedSections, setExpandedSections] = useState({
      chartType: true,
      metrics: true,
      axis: true,
      display: true,
      colors: true
    });

  const handleMetricAdd = () => {
    onChange({
      metrics: [...settings.metrics, '']
    });
  };

  const handleMetricRemove = (index: number) => {
    onChange({
      metrics: settings.metrics.filter((_, i) => i !== index)
    });
  };

  const handleMetricChange = (index: number, value: string) => {
    const newMetrics = [...settings.metrics];
    newMetrics[index] = value;
    onChange({ metrics: newMetrics });
  };

    const handleAxisChange = (axis: 'xAxis' | 'yAxis', key: string, value: any) => {
      onChange({
        [axis]: {
          ...settings[axis],
          [key]: value
        }
      });
    };
  return (
    <div className="space-y-8">
      <Section
        title="Chart Type"
        icon={PieChart}
        expanded={expandedSections.chartType}
        onToggle={() => setExpandedSections(prev => ({ ...prev, chartType: !prev.chartType }))}
      >
        <div className="grid grid-cols-3 gap-4">
          {chartTypes.map(type => (
            <button
              key={type.value}
              onClick={() => onChange({ type: type.value })}
              className={`flex flex-col items-center p-4 rounded-lg border-2 transition-all ${
                settings.type === type.value
                  ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                  : 'border-gray-200 dark:border-gray-700 hover:border-blue-300'
              }`}
            >
              <type.icon className={`w-8 h-8 mb-2 ${
                settings.type === type.value
                  ? 'text-blue-500'
                  : 'text-gray-500 dark:text-gray-400'
              }`} />
              <span className={`text-sm font-medium ${
                settings.type === type.value
                  ? 'text-blue-600 dark:text-blue-400'
                  : 'text-gray-600 dark:text-gray-300'
              }`}>
                {type.label}
              </span>
            </button>
          ))}
        </div>
      </Section>

      {/* Metrics */}
      <Section
        title="Metrics"
        icon={Type}
        expanded={expandedSections.metrics}
        onToggle={() => setExpandedSections(prev => ({ ...prev, metrics: !prev.metrics }))}
      >
        <div className="space-y-4">
          {settings.metrics.map((metric, index) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="text"
                value={metric}
                onChange={(e) => handleMetricChange(index, e.target.value)}
                placeholder="Enter metric name"
                className={`flex-1 rounded-lg px-3 py-2 ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white border-gray-600' 
                    : 'bg-white text-gray-900 border-gray-300'
                } focus:ring-2 focus:ring-blue-500`}
              />
              <button
                onClick={() => handleMetricRemove(index)}
                className="p-2 text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          ))}
          <button
            onClick={handleMetricAdd}
            className={`w-full py-2 rounded-lg border-2 border-dashed ${
              isDarkMode
                ? 'border-gray-700 hover:border-gray-600'
                : 'border-gray-300 hover:border-gray-400'
            } flex items-center justify-center`}
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Metric
          </button>
        </div>
      </Section>

      {/* Axis Configuration */}
      <Section
        title="Axis Configuration"
        icon={ArrowLeftRight}
        expanded={expandedSections.axis}
        onToggle={() => setExpandedSections(prev => ({ ...prev, axis: !prev.axis }))}
      >
        {/* X-Axis */}
        <div className="space-y-4">
          <h4 className={`text-sm font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}>
            X-Axis
          </h4>
          <div className="space-y-3">
            <input
              type="text"
              value={settings.xAxis.dataKey}
              onChange={(e) => handleAxisChange('xAxis', 'dataKey', e.target.value)}
              placeholder="Data Key"
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-white border-gray-600' 
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
            />
            <input
              type="text"
              value={settings.xAxis.label}
              onChange={(e) => handleAxisChange('xAxis', 'label', e.target.value)}
              placeholder="Label"
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-white border-gray-600' 
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
            />
            <div>
              <label className="text-sm text-gray-500 dark:text-gray-400">
                Tick Rotation
              </label>
              <input
                type="range"
                min="-90"
                max="90"
                value={settings.xAxis.tickRotation}
                onChange={(e) => handleAxisChange('xAxis', 'tickRotation', parseInt(e.target.value))}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>-90°</span>
                <span>{settings.xAxis.tickRotation}°</span>
                <span>90°</span>
              </div>
            </div>
          </div>

          {/* Y-Axis */}
          <h4 className={`text-sm font-medium mt-6 ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}>
            Y-Axis
          </h4>
          <div className="space-y-3">
            <input
              type="text"
              value={settings.yAxis.dataKey}
              onChange={(e) => handleAxisChange('yAxis', 'dataKey', e.target.value)}
              placeholder="Data Key"
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-white border-gray-600' 
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
            />
            <input
              type="text"
              value={settings.yAxis.label}
              onChange={(e) => handleAxisChange('yAxis', 'label', e.target.value)}
              placeholder="Label"
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-white border-gray-600' 
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
            />
            <div>
              <label className="text-sm text-gray-500 dark:text-gray-400">
                Tick Rotation
              </label>
              <input
                type="range"
                min="-90"
                max="90"
                value={settings.yAxis.tickRotation}
                onChange={(e) => handleAxisChange('yAxis', 'tickRotation', parseInt(e.target.value))}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>-90°</span>
                <span>{settings.yAxis.tickRotation}°</span>
                <span>90°</span>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Display Options */}
      <Section
        title="Display Options"
        icon={Eye}
        expanded={expandedSections.display}
        onToggle={() => setExpandedSections(prev => ({ ...prev, display: !prev.display }))}
      >
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className={`text-sm font-medium ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Show Legend
            </label>
            <input
              type="checkbox"
              checked={settings.showLegend}
              onChange={(e) => onChange({ showLegend: e.target.checked })}
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center justify-between">
            <label className={`text-sm font-medium ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Show Grid
            </label>
            <input
              type="checkbox"
              checked={settings.showGrid}
              onChange={(e) => onChange({ showGrid: e.target.checked })}
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center justify-between">
            <label className={`text-sm font-medium ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Show Tooltip
            </label>
            <input
              type="checkbox"
              checked={settings.showTooltip}
              onChange={(e) => onChange({ showTooltip: e.target.checked })}
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center justify-between">
            <label className={`text-sm font-medium ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Stacked
            </label>
            <input
              type="checkbox"
              checked={settings.stacked}
              onChange={(e) => onChange({ stacked: e.target.checked })}
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>
        </div>
      </Section>

      {/* Colors */}
      <Section
        title="Colors"
        icon={Layers}
        expanded={expandedSections.colors}
        onToggle={() => setExpandedSections(prev => ({ ...prev, colors: !prev.colors }))}
      >
        <div className="space-y-4">
          <div className="flex flex-wrap gap-2">
            {settings.colors?.map((color, index) => (
              <div key={index} className="relative group">
                <input
                  type="color"
                  value={color}
                  onChange={(e) => {
                    const newColors = [...(settings.colors || [])];
                    newColors[index] = e.target.value;
                    onChange({ colors: newColors });
                  }}
                  className="w-10 h-10 rounded-lg cursor-pointer"
                />
                <button
                  onClick={() => {
                    const newColors = (settings.colors || []).filter((_, i) => i !== index);
                    onChange({ colors: newColors });
                  }}
                  className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-white 
                  opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center text-xs"
                >
                  ×
                </button>
              </div>
            ))}
            <button
              onClick={() => {
                const newColors = [...(settings.colors || []), '#000000'];
                onChange({ colors: newColors });
              }}
              className={`w-10 h-10 rounded-lg border-2 border-dashed ${
                isDarkMode ? 'border-gray-700' : 'border-gray-300'
              } flex items-center justify-center`}
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ChartSettings;