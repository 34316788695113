import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { Widget, WidgetConfigWithRefresh } from '../../types/widget';
import WidgetContent from './WidgetContent';
import { DataSettings } from '../../types/shared';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter
} from '../ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { 
  Maximize2, 
  Minimize2, 
  MoreVertical,
  RefreshCw,
  Edit,
  Trash2,
  Settings,
  SlidersHorizontal,
  ExternalLink,
  Share2,
  Code
} from 'lucide-react';
import { LoadingSpinner } from '../common/LoadingSpinner';

interface WidgetCardProps {
  widget: Widget;
  onDelete?: () => Promise<void>;
}

interface RefreshConfig extends DataSettings {
  autoRefresh?: boolean;
  refreshInterval?: number;
}





export const WidgetCard: React.FC<WidgetCardProps> = ({ widget, onDelete }) => {
  const navigate = useNavigate();
  const { updateWidget, isLoading, error: serviceError } = useWidget(widget.id);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const { generateContent } = useWidget();
  
  

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      if (widget.config.inputs) {
        await generateContent(widget.id, widget.config.inputs);
      }
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleToggleAutoRefresh = async () => {
    try {
      const newConfig = { ...widget.config };
      if (!newConfig.data) {
        newConfig.data = {
          aggregation: 'none',
          sorting: 'asc',
          filtering: [],
          transforms: [],
          autoRefresh: true,
          refreshInterval: 30
        };
      } else {
        (newConfig as WidgetConfigWithRefresh).data = {
          ...newConfig.data,
          autoRefresh: !(newConfig as WidgetConfigWithRefresh).data?.autoRefresh
        };
      }
      await updateWidget(widget.id, { config: newConfig });
    } catch (error) {
      console.error('Failed to toggle auto-refresh:', error);
    }
  };

  const handleRefreshIntervalChange = async (interval: number) => {
    try {
      const newConfig = { ...widget.config };
      if (!newConfig.data) {
        newConfig.data = {
          aggregation: '',
          sorting: 'asc',
          filtering: [],
          transforms: [],
          autoRefresh: true,
          refreshInterval: interval
        };
      } else {
        newConfig.data.refreshInterval = interval;
      }
      await updateWidget(widget.id, { config: newConfig });
    } catch (error) {
      console.error('Failed to update refresh interval:', error);
    }
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleExport = () => {
    const dataStr = JSON.stringify(widget, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
    const exportFileDefaultName = `widget-${widget.id}.json`;
    
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const handleCustomize = () => {
    navigate(`/dashboard/customize/${widget.id}`);
  };

  return (
    <Card className={`${
      isExpanded ? 'fixed inset-4 z-50' : 'h-[400px]'
    } overflow-hidden transition-all duration-300`}>
      <CardHeader className="pb-3">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle>{widget.name}</CardTitle>
            {widget.config.description && (
              <CardDescription>{widget.config.description}</CardDescription>
            )}
          </div>
          <div className="flex items-center space-x-2">
            {/* Refresh Button */}
            <Button
              variant="ghost"
              size="sm"
              onClick={handleRefresh}
              disabled={isRefreshing || isLoading}
              title="Refresh Data"
            >
              <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
            </Button>

            {/* Expand/Collapse Button */}
            <Button
              variant="ghost"
              size="sm"
              onClick={toggleExpand}
              title={isExpanded ? 'Collapse' : 'Expand'}
            >
              {isExpanded ? (
                <Minimize2 className="h-4 w-4" />
              ) : (
                <Maximize2 className="h-4 w-4" />
              )}
            </Button>

            {/* Widget Menu */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={handleCustomize}>
                  <SlidersHorizontal className="mr-2 h-4 w-4" />
                  Customize
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setShowSettings(!showSettings)}>
                  <Settings className="mr-2 h-4 w-4" />
                  Settings
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setShowCode(!showCode)}>
                  <Code className="mr-2 h-4 w-4" />
                  View Code
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleExport}>
                  <Share2 className="mr-2 h-4 w-4" />
                  Export Widget
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleToggleAutoRefresh}>
                  <RefreshCw className="mr-2 h-4 w-4" />
                  {(widget.config as WidgetConfigWithRefresh).data?.autoRefresh ? 'Disable' : 'Enable'} Auto-refresh
                </DropdownMenuItem>
                {onDelete && (
                  <DropdownMenuItem 
                    className="text-red-600"
                    onClick={onDelete}
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    Delete
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </CardHeader>

      <CardContent>
        {showSettings ? (
          // Settings Panel
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Auto-refresh</span>
              <div className="relative inline-block w-10 mr-2 align-middle select-none">
                <input
                  type="checkbox"
                  checked={(widget.config as WidgetConfigWithRefresh).data?.autoRefresh}
                  onChange={handleToggleAutoRefresh}
                  className="toggle"
                />
              </div>
            </div>
            {(widget.config as WidgetConfigWithRefresh).data?.autoRefresh && (
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Refresh interval</span>
                <select
                  value={(widget.config as WidgetConfigWithRefresh).data?.refreshInterval || 30}
                  onChange={(e) => handleRefreshIntervalChange(Number(e.target.value))}
                  className="select select-sm"
                >
                  <option value={30}>30 seconds</option>
                  <option value={60}>1 minute</option>
                  <option value={300}>5 minutes</option>
                  <option value={600}>10 minutes</option>
                </select>
              </div>
            )}
          </div>
        ) : showCode ? (
          // Code View
          <pre className="overflow-auto max-h-[300px] p-4 bg-gray-100 dark:bg-gray-900 rounded">
            <code>{JSON.stringify(widget.content?.code || widget.config, null, 2)}</code>
          </pre>
        ) : (
          // Widget Content
          <div className={`${isExpanded ? 'h-full' : 'h-[300px]'} overflow-auto`}>
            <WidgetContent
              widget={widget}
              isLoading={isLoading}
              error={serviceError}
            />
          </div>
        )}
      </CardContent>

      <CardFooter className="pt-3">
        <div className="flex justify-between items-center w-full text-sm text-gray-500">
          <span>Updated {new Date(widget.updatedAt).toLocaleTimeString()}</span>
          <div className="flex items-center space-x-2">
            <span className="capitalize">{widget.widgetType.toLowerCase()}</span>
            
              <span className="text-xs bg-blue-100 text-blue-800 rounded-full px-2 py-0.5">
                
              </span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default WidgetCard;