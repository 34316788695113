// src/hooks/useWidgetCustomization.ts
import { useState, useCallback, useEffect } from 'react';
import { 
  Widget, 
  DEFAULT_VISUAL_CONFIG,
  DEFAULT_DATA_CONFIG,
  DEFAULT_CHART_CONFIG,
  isChartWidget 
} from '../types/widget';
import { 
  CustomizationState,
  CustomizationAction,
  VisualCustomization,
  DataCustomization,
  ChartCustomization
} from '../types/customization';
import { 
  VisualSettings,
  DataSettings,
  ChartSettings
} from '../types/shared';

export function useWidgetCustomization(widget: Widget) {
  const [customizations, setCustomizations] = useState<CustomizationState>(() => ({
    visual: {
      theme: {
        fontSize: 'md',
        config: widget.config.visual || DEFAULT_VISUAL_CONFIG
      }
    },
    data: {
      settings: widget.config.data || DEFAULT_DATA_CONFIG
    },
    chart: isChartWidget(widget.config) ? {
      settings: {
        ...DEFAULT_CHART_CONFIG,
        ...widget.config.chartConfig
      }
    } : null
  }));

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const applyCustomization = useCallback((action: CustomizationAction) => {
    setCustomizations(prev => ({
      ...prev,
      [action.category]: {
        ...(prev[action.category] as any),
        settings: {
          ...(prev[action.category] as any).settings,
          ...action.settings
        }
      }
    }));
    setHasUnsavedChanges(true);
  }, []);

  const resetCustomizations = useCallback(() => {
    setCustomizations({
      visual: {
        theme: {
          fontSize: 'md',
          config: widget.config.visual || DEFAULT_VISUAL_CONFIG
        }
      },
      data: {
        settings: widget.config.data || DEFAULT_DATA_CONFIG
      },
      chart: isChartWidget(widget.config) ? {
        settings: {
          ...DEFAULT_CHART_CONFIG,
          ...widget.config.chartConfig
        }
      } : null
    });
    setHasUnsavedChanges(false);
  }, [widget.config]);

  useEffect(() => {
    resetCustomizations();
  }, [resetCustomizations]);

  return {
    customizations,
    hasUnsavedChanges,
    applyCustomization,
    resetCustomizations
  };
}